<!-- <ul class="nav-mobile">       
    <li></li>
    <li class="menu-container"> 
      <input id="menu-toggle" type="checkbox">
      <label for="menu-toggle" class="menu-button">  
        <svg class="icon-open" viewBox="0 0 24 24"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
        <svg class="icon-close" viewBox="0 0 100 100">
          <path d="M83.288 88.13c-2.114 2.112-5.575 2.112-7.69 0L53.66 66.188c-2.113-2.112-5.572-2.112-7.686 0l-21.72 21.72c-2.114 2.113-5.572 2.113-7.687 0l-4.693-4.692c-2.114-2.114-2.114-5.573 0-7.688l21.72-21.72c2.112-2.115 2.112-5.574 0-7.687L11.87 24.4c-2.114-2.113-2.114-5.57 0-7.686l4.842-4.842c2.113-2.114 5.57-2.114 7.686 0l21.72 21.72c2.114 2.113 5.572 2.113 7.688 0l21.72-21.72c2.115-2.114 5.574-2.114 7.688 0l4.695 4.695c2.112 2.113 2.112 5.57-.002 7.686l-21.72 21.72c-2.112 2.114-2.112 5.573 0 7.686L88.13 75.6c2.112 2.11 2.112 5.572 0 7.687l-4.842 4.84z"/>
        </svg> 
      </label>      
      <ul class="menu-sidebar">
        <li><a [routerLink]="['/']">HOME</a></li>
        <li><a href="#">MENUS</a></li>
        <li><a href="#">TABLE BOOKING</a></li>
        <li><a href="#">GIFT A MEAL</a></li>
        <li><a href="#">REWARD</a></li>
        <li><a href="#">CONTACT</a></li>
        <li><a [routerLink]="['/product']">PRODUCT</a></li>
        <li><a [routerLink]="['/checkout']">CHECKOUT</a></li>
                                             
      </ul> 
    </li>
  </ul> -->
<header style="height: 65px; background-color: #1746A2;">
	<div class="mobile-header"></div>
	<section class="inq-news-st" style="margin: 0 !important;padding: 0;">
		<div class="row">
			<div class="col-md-12 text-center">
				<div _ngcontent-hfa-c69="" class="text-left" style="color: #fff">
					<div style="font-weight: 800; font-size: 2rem; padding: 10%; padding-top:3% !important; text-shadow: 0px 2px 2px #FF731D; background-color: #1746A2;">PresentSir</div>
				</div>
				<div style="float:right!important; padding-top: 10px; ">
					<button class="btn btn-outline-primary button-mobile" (click)="onlogin()" style="margin-right: 1rem;"><i
							class="fa fa-sign-in" style="margin-right: 0.5rem;"></i>Login</button><span
						class="dropdown">
					</span>
					<button class="btn btn-outline-primary button-mobile" (click)="onregistration()"
						style="margin-right: 1rem;"><i
						class="fa fa-sign-in" style="margin-right: 0.5rem;"></i>Register</button><span class="dropdown">
					</span>
				</div>
			</div>
		</div>
	</section>
</header>
<main>
	<div class="home-banner-mob">
		<img src="assets/images/homepage-mob1.png" style="width: 100%;">		
	</div>
	<div class="home-banner-web">
		<img src="assets/images/homepage.png" style="width: 100%;">
	</div>
	<!-- <router-outlet></router-outlet> -->
	<section class="btn-place-mob"  style="background-color: #FFF7E9;">
		<div style="padding-top: 10px; margin-left: 10px;height: 50px; display: grid;">
			<button class="btn btn-mob button-mobile" (click)="onlogin()" style="margin-right: 1rem;"><i
					class="fa fa-sign-in" style="margin-right: 0.5rem;"></i>Login</button><span class="dropdown">
			</span>
			<hr style="color:#FFF7E9; margin: 0.2rem">
			<button class="btn btn-mob button-mobile"  (click)="onregistration()"
				style="margin-right: 1rem;"><i
				class="fa fa-sign-in" style="margin-right: 0.5rem;"></i>Register</button><span class="dropdown">
			</span>
		</div>
	</section>
	<div class="home-banner-mob">
		<img src="assets/images/homepage-mob2.png" style="width: 100%;">	
		<hr>	
	</div>
	<section class="inq-news-st" style="background-color: #FFF7E9;">
		<div class="row">
			<div class="col-md-3"> </div>
			<div class="col-md-6 text-center">
				<h2>Top Categories from our networks</h2>
			</div>
			<div class="col-md-3"> </div>
		</div>
		<div class="home-page-categories" >
			<table class="table table-responsive home-page-table">
				<tr>
					<td style="padding: 1.2rem !important;" class="flip-card">
						<div class="flip-card-inner">
							<div class="text-center card" style=" align-items: center;">
								<img src="assets/images/networking.gif" style="height:3.5rem; width:3.5rem;">
								<!-- <i class="fa fa-cutlery" style="color: #2d2867;" aria-hidden="true"></i> -->
								<h5 class="card-heading">
									Networking
								</h5>
							</div>
							<div class="card-back">
								Networking is the exchange of information and ideas among people with a common profession or special interest, usually in an informal social setting
							</div>
						</div>
					</td>
					<td style="padding: 1.2rem !important;" class="flip-card">
						<div class="flip-card-inner">
							<div class="text-center card" style=" align-items: center;">
								<!-- <i class="fa fa-laptop" style="color: #2d2867;" aria-hidden="true"></i> -->
								<img src="assets/images/social.gif" style="height:3.5rem; width:3.5rem;">
								<h5 class="card-heading">
									Social Meetups
								</h5>
							</div>
							<div class="card-back">
								A meetup is an organized gathering of people, especially a regular meeting of people who share a common interest or hobby
							</div>
						</div>
					</td>
					<td style="padding: 1.2rem !important;" class="flip-card">
						<div class="flip-card-inner">
							<div class="text-center card" style=" align-items: center;">
								<!-- <i class="fa fa-television" style="color: #2d2867;" aria-hidden="true"></i> -->
								<img src="assets/images/philanthropy.gif" style="height:3.5rem; width:3.5rem;">
								<h5 class="card-heading">
									Philanthropy
								</h5>
							</div>
							<div class="card-back">
								Philanthropy is charitable giving by individuals and organizations to worthy causes and includes donating money, time, and other forms of altruism
							</div>
						</div>
					</td>
					<td style="padding: 1.2rem !important;" class="flip-card">
						<div class="flip-card-inner">
							<div class="text-center card" style=" align-items: center;">
								<!-- <i class="fa fa-money" style="color: #2d2867;" aria-hidden="true"></i> -->
								<img src="assets\images\business.gif" style="height:3.5rem; width:3.5rem;">
								<h5 class="card-heading">
									Business Meetups
								</h5>
							</div>
							<div class="card-back">
								A business meeting is a gathering of two or more people for the purpose of making decisions or discussing company objectives and operations
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td style="padding: 1.2rem !important; justify-content: center;" class="flip-card">
						<div class="flip-card-inner">
							<div class="text-center card" style=" align-items: center;">
								<!-- <i class="fa fa-graduation-cap" style="color: #2d2867;" aria-hidden="true"></i> -->
								<img src="assets/images/club.gif" style="height:3.5rem; width:3.5rem;">
								<h5 class="card-heading">
									Club Activities
								</h5>
							</div>
							<div class="card-back">
								Club Activities means all activities conducted under the auspices of the club, including all meetings, exhibitions and displays of any kind by members
							</div>
						</div>
					</td>
					<td style="padding: 1.2rem !important;" class="flip-card">
						<div class="flip-card-inner">
							<div class="text-center card" style=" align-items: center;">
								<!-- <i class="fa fa-bolt" style="color: #2d2867;" aria-hidden="true"></i> -->
								<img src="assets/images/sports.gif" style="height:3.5rem; width:3.5rem;">
								<h5 class="card-heading">
									Sports Meetup
								</h5>
							</div>
							<div class="card-back">
								a sports competition between two or more teams, involving various different running races and team events.
							</div>
						</div>
					</td>
					<td style="padding: 1.2rem !important;" class="flip-card">
						<div class="flip-card-inner">
							<div class="text-center card" style=" align-items: center;">
								<!-- <i class="fa fa-headphones" style="color: #2d2867;" aria-hidden="true"></i> -->
								<img src="assets/images/camera.gif" style="height:3.5rem; width:3.5rem;">
								<h5 class="card-heading">
									Photography Club
								</h5>
							</div>
							<div class="card-back">
								Photography Club is a team of people who focus to imagine, capture and freeze a moment and create beauty with like-minded friends.
							</div>
						</div>
					</td>
					<td style="padding: 1.2rem !important;" class="flip-card">
						<div class="flip-card-inner">
							<div class="text-center card" style=" align-items: center;">
								<!-- <i class="fa fa-video-camera" style="color: #2d2867;" aria-hidden="true"></i> -->
								<img src="assets/images/volunteering.gif" style="height:3.5rem; width:3.5rem;">
								<h5 class="card-heading">
									Volunteers
								</h5>
							</div>
							<div class="card-back">
								Volunteering is a voluntary act of an individual or group freely giving time and labor for community service
							</div>
						</div>
					</td>
				</tr>
			</table>
		</div>
		<!-- <div class="row">
          
        <div class="col-md-3 text-centercard" style="text-align: center !important;box-shadow: 0 4px 10px 0 rgb(175 165 165 / 20%) !important; background: #FFF;font-size: 3.5rem;">
            <i class="fa fa-graduation-cap " style="color: #1746A2;" aria-hidden="true"></i>
        </div>
        <div class="col-md-3 text-center card" style="text-align: center !important;box-shadow: 0 4px 10px 0 rgb(175 165 165 / 20%) !important; background: #FFF;font-size: 3.5rem;">
            <i class="fa fa-bolt" style="color: #1746A2;" aria-hidden="true"></i>
        </div>
        <div class="col-md-3 text-center card" style="text-align: center !important;box-shadow: 0 4px 10px 0 rgb(175 165 165 / 20%) !important; background: #FFF;font-size: 3.5rem;">
            <i class="fa fa-headphones" style="color: #1746A2;" aria-hidden="true"></i>
        </div>
        <div class="col-md-3 text-center card" style="text-align: center !important;box-shadow: 0 4px 10px 0 rgb(175 165 165 / 20%) !important; background: #FFF;font-size: 3.5rem;">
            <i class="fa fa-video-camera" style="color: #1746A2;" aria-hidden="true"></i>
        </div>
    </div> -->
	</section>
	<section class="inq-news-st bottom-container">
		<div>
			<div class="row inq-com-pd-st">
				<div class="col-md-8"style="box-shadow: 0 4px 10px 0 rgb(175 165 165 / 20%) !important; background-color: white;">
					<div class="row" style="margin: 2.5rem;">
						<table class="table table-responsive bottom-table" style="justify-content: center;">
							<tr>
								<td>
							<tr class="test">
								<div class="alignment">
									<td><span class="bottom-heading">{{institute_number}}</span></td>
									<td style="border-right: 1px solid rgb(215, 208, 208);" >
										<p class="vertical-center" style="font-size: 1rem;">Total Institutes Represented</p>
									</td>
								</div>
								<hr style="margin:0.2rem">
								<div class="alignment">
									<td style="padding-left:3rem"><span class="bottom-heading">{{member_number}}</span></td>
									<td>
										<p class="vertical-center" style="font-size: 1rem;">Total Members in the System</p>
									</td>
								</div>
							</tr>
							</td>

							</tr>
						</table>
					</div>
				</div>
				<div class="col-md-2" style="margin-left: 2rem;"> </div>
			</div>
		</div>
	</section>
	<section class="inq-news-st" style="margin-top: 0 !important;background-color: #1746A2  !important;">
		<div class="row justify-content-center">
			<div class="col-md-12">
				<div class="row inq-com-pd-st">
					<div class="col-md-3"> </div>
					<div class="col-md-6 invite-box" >
						<h5 class="" style="color:#fff;font-size: 2rem;">Get the most out of your neighbourhood</h5>
						<p style="color:#fff;font-size: 0.8rem;padding-bottom: 2rem;"> There are many more things you
							can do to make your neighborhood healthy</p>
						<!-- <p style="color:#fff;font-size: 0.8rem;padding-bottom: 2rem;"> Lorem Ipsum Lorem Ipsum Lorem Ipsum </p> -->
						<button type="button" class="text-uppercase btn btn-danger">Invite People Now </button>
					</div>
					<!-- <div class="col-md-5">
                        
                    </div> -->
					<div class="col-md-3"> </div>
				</div>
			</div>
		</div>
	</section>
</main>
<footer>
	<!-- newsletter -->
	<!-- newsletter -->
	<div class="container flex-container" style="padding: 0 !important;max-width:100% !important;">
		<div class="inq-foot-bg-st" style="background-color: #151513 !important;">
			<div class="row justify-content-center">
				<div class="col-md-10">
					<div class="row inq-com-pd-st">
						<div class="col-md-3" >
							<div >
								<h5 class="footer-items" >Quick Links</h5>
									<tr class="footer-items">
										<td class="footer-container">
											<p class="lol">Home</p>
											<p>About</p>
											<p>Events</p>
											<p>FAQs</p>
											<p>Contact Us</p>
										</td>
									</tr>
									<!-- <tr>
                                        <td style="border-top: 1px solid #b3aeaedb;font-size: 0.8rem;padding-top: 1rem;padding-bottom: 1rem;">
                                        Copyrights@PRESENTSIR
                                        </td>
                                            
                                        
                                    </tr> -->

								<!-- <h6>Haven Sampoorna Velachery</h6> -->
								<!-- <ul>
                                    <li><i class="fa fa-map-marker"></i> 31, Velachery Bypass Rd, Periyar Nagar, Velacheri, Chennai, Tamilnadu - 600042</li>
                                    <li><i class="fa fa-phone"></i> +91 9092482482 </li>
                                </ul> -->
							</div>
						</div>
						<div class="col-md-3 footer-items" style="padding-top:2.55%">
							<div class="footer-container" style="width: 255px !important;">
										<td style="position:relative;left:4px"> <img style="width: 228px !important;"src="assets/images/become_a_member_section.jpg"> </td>
								<!-- <h5>Follow Us On</h5> -->
								<!-- <h6>Haven Sampoorna Besant</h6> -->
								<!-- <ul>
                                    <li><i class="fa fa-map-marker"></i> 5,2nd Ave, Besant Nagar, <br>Chennai, Tamilnadu - 600042</li>
                                    <li><i class="fa fa-phone"></i> +91 9092482482 | +91 9962082082</li>
                                    <li><i class="fa fa-envelope"></i> mahen.nr@gmail.com</li>
                                </ul> -->
							</div>
						</div>
						<div class="col-md-3 footer-items" style="padding-top:2.55%">
							<div class="">
									<tr>
										<td class="footer-container">
											<p><i class="fa fa-map-marker" style="color: #bcc1c1;"
													aria-hidden="true"></i>&nbsp;&nbsp;318/1, Begur Road,</p>
											<p><i class="fa" style="color: #bcc1c1;"
													aria-hidden="true"></i>&nbsp;&nbsp;&nbsp;&nbsp;Bommanahalli,Bengaluru</p>
											<p><i class="fa fa-phone" style="color: #bcc1c1;"
													aria-hidden="true"></i>&nbsp;&nbsp;(+91) 97877 71359</p>
											<!-- <p><i class="fa fa-fax" style="color: #bcc1c1;"
													aria-hidden="true"></i>&nbsp;&nbsp;856-424-5572</p> -->
											<p><i class="fa fa-envelope" style="color: #bcc1c1;"
													aria-hidden="true"></i>&nbsp;&nbsp;shankar@susibala.com</p>
										</td>
									</tr>
								<!-- <ul>
                                    <li><img src="assets/images/time.svg" alt="clock"> Lunch - 11.30 AM to 6.30 PM</li>
                                    <li><img src="assets/images/time.svg" alt="clock"> Dinner - 06.30 AM to 10.00 PM</li>
                                    <li class="mt-3"><button class="btn btn-primary inq-btn-st">BOOK A TABLE</button></li>
                                </ul> -->
							</div>
						</div>
						<div class="col-md-3 footer-items">
							<div class="">
								<h5>Follow Us On</h5> 
									<span style="margin-right: 0.8rem; "><span class="fa fa-facebook" style="background: #2d6fd2;
                                    padding: 10px; width:35px; height: 35px;
                                    border-radius: 50%;
                                    font-size: 0.9rem;" aria-hidden="true"></span></span>

									<span style="margin-right: 0.8rem;"><span class="fa fa-youtube" style="background:red;
                                    padding: 10px; width:35px; height: 35px;
                                    border-radius: 25px;
                                    font-size: 0.9rem;" aria-hidden="true"></span></span> 

									<span style="margin-right: 0.8rem;"> <span class="fa fa-twitter" style="background:rgb(110, 186, 249);
                                    padding: 10px; width:35px; height: 35px;
                                    border-radius: 25px;
                                    font-size: 0.9rem;" aria-hidden="true"></span></span>
								
							</div>
						</div>
						<br>
						<hr style="margin-top:2%">
							<div style="font-size: 0.8rem;padding-bottom: 1rem;">
								Copyrights@SUSIBALAGARMENTS</div>
						
					</div>
				</div>
			</div>
			<!-- <div class="inq-footql-st">
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-3">
                                        <div class="inq-foot-logo-st">
                                            <img src="assets/images/logo.png" alt="Haven Logo">
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <p class="inq-foot-pd-st">&copy; 2021 Haven Sampoorna | All Rights Reserved</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="inq-foot-links-st inq-foot-pd-st">
                                    <ul>
                                        <li> About</li>
                                        <li>Contact</li>
                                        <li>Blog</li>
                                        <li>Gallery</li>
                                        <li>FAQ</li>
                                        <li>Gift a Meal</li>
                                        <li>Feedback</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
		</div>
	</div>
</footer>