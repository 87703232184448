import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, Event as NavigationEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ApiService } from '../app/services/api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ChatService } from 'src/app/member/chat/chat.service';
import { SwPush, SwUpdate } from '@angular/service-worker'
import { ENDPOINTS } from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  readonly VAPID_PUBLIC_KEY = "BECXeIA8gjqveHeZP3r67maMUiWIL-662wTOR1AoU6uWuz3Hmq19ZLnWmnMttHo8TcB6m3o23Dz1Av9fyYzYn50";
  title = 'PRESENTSIR';
  page:string="home";
  showSidebar = true;
  registration = false;
  flag=false
  userId : any
  constructor(
    private router: Router,
    private apiService : ApiService,
    private localService : LocalStorageService,
    private chatService : ChatService,
    private swPush: SwPush,
    private swUpdate:SwUpdate,
  ) {
    //swUpdate.available.subscribe(event => {
      //console.log("update found");
      //swUpdate.activateUpdate().then(() => document.location.reload());
    //})
    //this.subscribeNotification()
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationEvent) => {
      let events:any = event;
      console.log(events.url)
      this.registration = false;
      if (events.url === '/') {
        this.page = 'home'
        this.showSidebar = true;
      }
      else if (events.url === '/login'){
        this.page = 'login'
        this.showSidebar = false;
      }
      else{
        this.page = 'dashboard'
        this.showSidebar = true;
      }
      if( (events.url).split('/')[2] === 'myprofile'|| (events.url).split('/')[2] === 'family' || (events.url).split('/')[2] === 'school' || (events.url).split('/')[2] === 'college' || (events.url).split('/')[2] === 'certification' || (events.url).split('/')[2] === 'profession' || (events.url).split('/')[1] === 'registration' || (events.url).split('/')[2] === 'personal' || (events.url).split('/')[1] === 'edit' || (events.url).split('/')[1] === 'forgot-password'){
        
        this.showSidebar = false;
        if((events.url).split('/')[1] === 'registration')
        {
          this.registration = true;
        }
      }

    })
   }

   ngOnInit(){
     this.apiService.PROFILE_PICTURE = this.localService.get('picture')
   }

   subscribeNotification(){
    this.swPush.notificationClicks.subscribe(({action,notification})=>{
      console.log("chk",notification)
      if(!this.flag){
        window.open(notification.data.url)
        this.flag=true
      }
    })
    this.flag=false

    this.swPush.requestSubscription({serverPublicKey:this.VAPID_PUBLIC_KEY})
    .then(pushSubsription=>{
      console.log(pushSubsription.toJSON())
      this.apiService.postApiCall(ENDPOINTS.CREATESUBSCRIBEDUSER,pushSubsription.toJSON()).subscribe(resp=>{
        console.log(resp)
        if(resp && resp.success ==1)
        {

        }
      },err=>
      {
        if (err && err.error && err.error.statusCode === 403) {
          //this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
          this.router.navigate([`/login`], { skipLocationChange: false });
        }
      })
    })
  }

}
