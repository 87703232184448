import { NgModule } from '@angular/core';
import { ModuleWithProviders } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { LoginComponent } from './login/login.component';
import { HeaderComponent } from './header/header.component';

import { SidebarFilterComponent } from './main/sidebar-filter/sidebar-filter.component';
import { ProfileCardsComponent } from './main/profile-cards/profile-cards.component';
import {MyAccountComponent} from './my-account/my-account.component';
import {HomeComponent} from './home/home.component';
import { EditComponent } from './edit/edit.component';
import { ChatComponent } from './chat/chat.component';
import { ChatService } from './chat/chat.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConnectionComponent } from './connection/connection.component';
import {SharedModule} from './shared/shared.moudule';
import { MobileChatComponent } from './mobile-chat/mobile-chat.component';
import { AgmCoreModule } from '@agm/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { PushNotificationComponent } from './push-notification/push-notification.component';

// import { GooglePlaceModule } from "ngx-google-places-autocomplete";
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarFilterComponent,
    ProfileCardsComponent,
    MyAccountComponent,
    HomeComponent,
    EditComponent,
    ChatComponent,
    ForgotPasswordComponent,
    ConnectionComponent,
    MobileChatComponent,
    PushNotificationComponent,
    
    
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    ToastrModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB0Pd6TkW7Ip2rIDZsXgPhyfLprulvDn7U',
      libraries: ['places']
    }),
    GooglePlaceModule,
    //ServiceWorkerModule.register('ngsw-worker.js', {
      //enabled: environment.production,
      //enabled:true
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:30000'
    //})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
