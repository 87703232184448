import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {LocalStorageService} from './local-storage.service'
import {NotificationService} from './notification.service';
import {ENDPOINTS} from '../app.config';
import { DOMAIN_NAME, USER_ID } from 'src/app/app.config';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  PROFILE_PICTURE : any;
  constructor(
    private http: HttpClient,
    private router: Router,
    private localService : LocalStorageService,
    private notificationService: NotificationService
  ) { }

      // API CALLS

  // GET
  getApiCall(api_endpoint: any, dataObj: any): Observable<any>{
    return this.http.get(api_endpoint, { headers: new HttpHeaders().set('Content-Type', 'application/json')});
  }

  // .set('Access-Control-Allow-Origin', '*')
  // POST
  postApiCall(api_endpoint: any, dataObj: any): Observable<any>{
    return this.http.post(api_endpoint, dataObj, { headers: new HttpHeaders().set('Content-Type', 'application/json').set('Access-Control-Allow-Origin', '*').set('authorization', 'Bearer ' + this.localService.get('accessToken'))});
  }

  loadDropdown(url:any,name:string,value:string)
  {

    return new Promise((resolve,reject)=>
    {
      let requestBody = {
        domain_name : this.localService.get('domainName'),
        user_id : this.localService.get('userId'),
        extras :  {find : {}}
      }
      this.postApiCall(url,requestBody).subscribe((resp)=>
      {
        if(resp && resp.success ==1)
        {
          let tempArray:any = []
          resp.result.data.forEach((element:any) => {
            let tempObj:any = {}
            tempObj[name] = element[name]
            tempObj[value]= element[value]
            tempArray.push(tempObj)
          });
          resolve(tempArray);
        }
        else
        {
          resolve([]);
        }
      },err=>
      {
        resolve([]);
        if (err && err.error && err.error.statusCode === 403){
          this.notificationService.showError('Session Time Out! Please login Again', 'Session time out')
          this.router.navigate([`/login`],{ skipLocationChange: false });
        }
      })
    })
  }

  uploadImage(data:any,source:any)
  {
    return new Promise((resolve,reject)=>
    {
      console.log("coming here")
      let formData = new FormData()
      let headers = new HttpHeaders().set("source",source).set("domain_name",this.localService.get('domainName'))
      formData.append('upload',data);
      console.log("domain",headers,source,formData)
      this.sendImage(formData,headers).subscribe((resp:any)=>
      {
        console.log("coming here")
        console.log(resp)
        if(resp && resp.success ==1)
        {
          console.log("res",resp)
          let imgVariable = resp.result.data;
          resolve(imgVariable[0].location);
        }
        else
        {
          console.log(resp)
          resolve(null)
        }
      },(err:any)=> {
        console.log(err,"err")
        resolve(null)
        if (err && err.error && err.error.statusCode === 403){
          this.notificationService.showError('Session Time Out! Please login Again', 'Session time out')
          this.router.navigate([`/login`],{ skipLocationChange: false });
        }
      })
    })
  }

  //sendimage
  sendImage(jsonObj:any,headers :any): Observable<any>{
    return this.http.post(ENDPOINTS.IMAGE_UPLOAD,jsonObj ,{headers: headers});
  }
  
}
