import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EditComponent } from './edit/edit.component';
import { HomeComponent } from './home/home.component';
import {LoginComponent} from './login/login.component'
import {MyAccountComponent} from './my-account/my-account.component';
import { ChatComponent } from './chat/chat.component';
import{ForgotPasswordComponent} from './forgot-password/forgot-password.component'
import { ConnectionComponent } from './connection/connection.component'
import { MobileChatComponent } from './mobile-chat/mobile-chat.component';
import {PushNotificationComponent} from './push-notification/push-notification.component'

const routes: Routes = [
    {
      path : 'my-account', component : MyAccountComponent
    },
    {
      path : 'notify', component : PushNotificationComponent
    },
    {
      path : 'chat', component : MobileChatComponent
    },
    {
      path : 'chat/:id', component : MobileChatComponent
    },

    {
      path : 'connection', component : ConnectionComponent
    },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule)
  },
  {
    path: 'member',
    loadChildren: () => import('./member/member.module').then(m => m.MemberModule)
  },
  {
    path: "",
    component: HomeComponent
  },
  {
    path: "edit/:id1",
    component: EditComponent
  },
  {
    path: "forgot-password",
    component: ForgotPasswordComponent
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path : 'events',loadChildren: () => import('./events/events.module').then(m => m.EventModule)
  }

  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
