<div class="fixed-header">
<nav class="navbar navbar-fixed-top navbar-light header" >
    <div style="width: 50%; float: left; color: #fff; font-weight: 800; padding-right: 10%; padding-left: 1%; font-size: 2rem; cursor: pointer; text-shadow: 0px  2px 2px #FF731D;" routerLink=''>PresentSir</div>
                    
                    <!--notification-->
                    <!--<div class="card notifi-box" id="box" style="overflow-y:auto;z-index: 600 !important;">
                        <h2>Notifications <span>{{events.length}}</span></h2>
                        <div class="notifi-item" style="z-index: 1000;" *ngFor="let event of events">
                            
                            <div class="text">
                               
                               <p>New event is created under {{event.group_name}}</p>
                            </div> 
                        </div>
        
                    </div>-->
    
    <div style="float: right;display:inline-flex" class="only-mobile">
        <!--<span (click)="toggleNotification()" class="border rounded-3 p-1 text-decoration-none" ><i class="fa fa-bell"></i></span>&nbsp;&nbsp;&nbsp;-->
        <p *ngIf="!registration" [ngStyle]="{'display' : (showSidebar) ? 'block' : 'none'}" class="border rounded-3 p-1 text-decoration-none" style="margin-right: 10px;" data-bs-target="#sidebar2" data-bs-toggle="collapse" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" (click)="sideBar()">
            <i class="fa fa-navicon"  style="color:white;margin:5px" ></i>
          </p>
    <a href="#" *ngIf="!registration"  [ngStyle]="{'display' : (!showSidebar) ? 'block' : 'none'}" style="float: right !important;margin-right: 10px !important;" data-bs-target="#sidebar" data-bs-toggle="collapse" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" (click)="sideBar()"
                class="border rounded-3 p-1 text-decoration-none" style="color:white; margin-right: 10px;"><i class="fa fa-navicon"  style="color:white;margin:5px" ></i></a>
    </div>
</nav>
</div>
<div class="container fluid" >
    <div class="row flex-nowrap">
        <div class="col-auto px-0" style="background-color: #FFF7E9 ; display: none" [ngStyle]="{'display' : (showSidebar) ? 'block' : 'none'}">
            <div id="sidebar2"  class="collapse collapse-horizontal show border-end" style="position: fixed;margin-top: 60px; z-index: 2;">
                <div class="sidebar-header">
                    <div class="d-flex justify-content-start">
                        <span class="logo"></span>
                    </div>
                </div>
                
                <div id="sidebar-nav" class="list-group border-0 rounded-0 text-sm-start min-vh-100" >
                    <a style="padding-top: 5px;margin-top: 18px; margin-left:3px">
                        <div class="top-container"> <img [src]="(profilePicture == '') ? 'assets/images/profile.png' : profilePicture"
                            class="img-fluid profile-image d-block mx-auto rounded" (click)="route()" width="120">
                        <div class="ml-4 text-center" style="margin-right: 1px;">
                            <div>
                                <b>{{userName}}</b>
                                <br>
                                <b>{{mobile}}</b>
                            </div>
    
                        </div>
                    </div>
                    </a>
                    <!-----approval-------->
                    <a [routerLink]="['/member/approve-reject']"  class="list-group-item border-end-0 d-inline-block text-truncate" style="border: none  !important;" [class.active]="activeMenu === 'approveReject'">
                        <span class="fa fa-check"></span>
                        <span class="ms-1 d-sm-inline">&nbsp;Invitation</span>
                        <!-- <span class="count d-sm-inline">&nbsp;212+</span> -->
                        </a>
                    <!-----invite-------->

                    <!-- <a [routerLink]="['/member/invite']" class="list-group-item border-end-0 d-inline-block text-truncate" [class.active]="activeMenu === 'invite'">
                        <i class="fa fa-user-plus"></i> 
                        <span class="ms-1 d-sm-inline">&nbsp;Invite</span>
                                <span class="count d-sm-inline"></span>
                            </a> -->
                    <!-----connection-------->

                    <a  [routerLink]="['/connection']" class="list-group-item border-end-0 d-inline-block text-truncate"  style="border: none  !important;"[class.active]="activeMenu === 'connection'"   >
                        <span class="fa fa-users"></span>
                        <span class="ms-1 d-sm-inline">&nbsp;Connection</span>
                        <span class="count d-sm-inline"></span>
                    </a>
                   <a  [routerLink]="['/chat']" class="list-group-item border-end-0 d-inline-block text-truncate mobile-view" style="border: none  !important;"[class.active]="activeMenu === 'chat'" data-bs-target="#sidebar2" data-bs-toggle="collapse" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" (click)="sideBar()">
                        <span class="fa fa-envelope"></span>
                        <span class="ms-1 d-sm-inline">&nbsp;Messaging</span>
                        <span class="count d-sm-inline"></span>
                    </a>
                    <a href="#" class="list-group-item border-end-0 d-inline-block text-truncate" [routerLink]="['/events/list-event']" style="border: none  !important;"[class.active]="activeMenu === 'events'">
                        <span class="fa fa-calendar"></span>
                        <span class="ms-1 d-sm-inline">&nbsp;Events</span>
                        <span class="count d-sm-inline"></span>
                    </a>

                    <!-------events-->
                    <a class="list-group-item border-end-0 d-inline-block text-truncate" [routerLink]="['/my-account']" style="border: none  !important;"[class.active]="activeMenu === 'my-account'">
                        <span class="fa fa-user"></span>
                        <span class="ms-1 d-sm-inline">&nbsp;My Account</span>
                        <span class="count d-sm-inline"></span>
                    </a>

                    <a class="list-group-item border-end-0 d-inline-block text-truncate" style="cursor: pointer;" style="border: none  !important;"(click)="logout()">
                        <span class="fa fa-sign-out"></span>
                        <span class="ms-1 d-sm-inline">&nbsp;Logout</span>
                        <span class="count d-sm-inline"></span>
                    </a>

                    <!--<a class="list-group-item border-end-0 d-inline-block text-truncate" [routerLink]="['/notify']" [class.active]="activeMenu === 'my-account'">
                        <i class="fa fa-user"></i>
                        <span class="ms-1 d-sm-inline">&nbsp;Notify</span>
                        <span class="count d-sm-inline"></span>
                    </a>-->
                </div>
            </div>
            <br>
        </div>
      
        <main class="col ps-md-2 pt-2 sidebar-mobile" id="main">
            <div [id]="main_container_class" class="main" style="margin-top: 60px;">
                <router-outlet></router-outlet>
                <app-chat *ngIf="!registration && showSidebar"></app-chat>

                    <!-- <div class="right-menu">
                        <div class="chat-text">

                            <div class="chat-box">
                              <div class="chat-head">
                                <h4>Messaging</h4>
                                  <span title="Expand Arrow" width="16" (click)="toggle()" style="float: right;"><i [class]="show ? 'fa fa-angle-down' : 'fa fa-angle-up'" style="color: white;height: 50px;width: 30px;margin-top: 5px;"></i></span>
                              </div>
                              <div id="a" class="chat-body">
                                <p>check</p>
                              </div>
                            </div>
                          </div>  
                          </div> -->
                    
          
                
                <!-- <div class="row" style="float: right;margin-left: 200px;">
                    <div class="col">
                        <div class="left-menu">
                            <p>work</p>
                        </div>
                    </div>
                    <div class="col">
                        <div class="right-menu">
                        <div class="chat-text">
                            <div class="chat-box">
                              <div class="chat-head">
                                <h2>Chat Box</h2>
                                  <span title="Expand Arrow" width="16" (click)="toggle()" style="float: right;"><i [class]="show ? 'fa fa-angle-up' : 'fa fa-angle-down'" style="color: white;height: 50px;width: 30px;margin-top: 10px;"></i></span>
                              </div>
                              <div id="a" class="chat-body" style="z-index:50;">
                                <p>check</p>
                              </div>
                            </div>
                          </div>  
                          </div>
                    </div>
                </div>
                 -->
            </div>
        </main>
    </div>

</div>