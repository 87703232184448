<!-- <p>member-registration works!</p> -->
<nav class="navbar navbar-fixed-top navbar-light header">
    <div style="width: 50%; float: left; color: #fff; font-weight: 800; padding-right: 10%; padding-left: 1%; font-size: 2rem; cursor: pointer; text-shadow: 0px  2px 2px #FF731D;" routerLink=''>PresentSir</div>
</nav>
<div id="main-wrapper" class="oxyy-login-register" style="background-color: #FFF7E9;">
    <div class="container-fluid">
        <div class="row min-vh-100">
            <!-- Welcome Text
          ========================= -->
            <div class="col-md-6" style="padding-right: 0%; padding-left: 0%;">
                <div class="hero-wrap d-flex align-items-center h-100">
                    <div class="hero-mask opacity-8 bg-primary"></div>
                    <div class="hero-bg hero-bg-scroll"
                        style="background-image:url(&#39;./assets/images/network.png&#39;);">
                    </div>
                    <div class="hero-content mx-auto w-100 h-100 d-flex flex-column">
                        <div class="row no-gutters my-auto">
                            <div class="col-10 col-lg-9 mx-auto">
                                <h1 class="text-11 text-white mb-4">Member Login</h1>
                                <h3 class="text-4 text-white line-height-4 mb-5"> God planned for our lives to connect. Let's make sure we keep it that way.</h3>
                                <button type="button" class="btn btn-secondary btn-sm" routerLink=''>Home</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- Welcome Text End -->

            <!-- Login Form
          ========================= -->
            <!-- <button type="button" class="btn btn-outline-primary btn-sm" routerLink=''>Home</button> -->

            <div class="col-md-6 d-flex align-items-center" style="padding-right: 0%; padding-left: 0%;">
                <div class="container my-auto py-5">
                    <div class="row">
                        <div class="col-11 col-lg-9 col-xl-8 mx-auto">
                  <h3 class="font-weight-600 mb-3" style="color:#1746A2; font-family:sans-serif;font-size:xx-large; text-align: center;">PresentSir</h3>
                  <h3 class="font-weight-100 mb-5" style=" color:#1746A2; font-size: x-large; text-align: center; ">Please Login to Continue</h3>
                  <br>


                  <form id="loginForm" [formGroup]="loginForm">
                    <div class="form-group">
                      <label for="emailAddress"  style="color:#1746A2;">Mobile</label>
                      <input type="text" class="form-control" formControlName="email" [class.is-invalid]="f.email.errors && validateForm" id="emailAddress" placeholder="Enter Your Mobile">
                      <div class="invalid-feedback" *ngIf="f.email.errors">
                        <div *ngIf="f.email.errors.required">Mobile required</div>
                    </div>
                    <div class="invalid-feedback" *ngIf="f.email.errors">
                        <div *ngIf="f.email.errors.pattern">Accept ony numbers with 10 digits</div>
                    </div>
                    </div>
                    <br>
                    <div class="form-group">
                      <label for="loginPassword" style="color:#1746A2;">Password</label>
                      <input type="password" formControlName="password" class="form-control" id="loginPassword"  [class.is-invalid]="f.password.errors && validateForm" placeholder="Enter Password">
                      <div class="invalid-feedback" *ngIf="f.password.errors">
                        <div *ngIf="f.password.errors.required">Password required!</div>
                    </div>
                    </div>
                    <button class="btn btn-primary btn-block my-5" type="submit"  style="float: right;"  (click)= "login()">Login</button>
                    <button class="btn btn-primary btn-block my-5" type="submit" style="float: left;" (click)= "forgot()">Forgot Password</button>
                  </form>
                </div>
                    </div>
                </div>
            </div>
            <!-- Login Form End -->
        </div>
    </div>
</div>