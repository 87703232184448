import { Injectable } from '@angular/core';
//import {io} from 'socket.io-client';
import { Observable } from 'rxjs';

import { Message} from './../../app/interfaces/message'
import { Auth } from './../../app/interfaces/auth'
import { SetUser } from './../../app/interfaces/setuser'
import { ChatListResponse } from './../../app/interfaces/chat-list-response'
import { LocalStorageService } from './../services/local-storage.service'
import { JsonPipe } from '@angular/common';
import { BehaviorSubject } from 'rxjs';
import * as io from 'socket.io-client' 
import {CHATAPI} from './../app.config';

@Injectable({
  providedIn: 'root'
})

export class ChatService {
    private url = CHATAPI;
	private socket : any;  
	private userData = new BehaviorSubject('');
	selectUserData = this.userData.asObservable()
    constructor(private localService : LocalStorageService) {
	}

  selectUser(name:any){
	this.userData.next(name)
  }

  connectSocket(userId:any){
	this.socket = io.connect(this.url,{query:{userId:"0000000"+userId},secure:true,rejectUnauthorized:false});
  }

  sendMessage(message : any) {
    this.socket.emit('add-message', message);
  }

  makeMessageRead(message : any) {
    this.socket.emit('message-read', message);
  }

  receiveMessages(): Observable<Message> {
		return new Observable(observer => {
			this.socket.on('add-message-response', (data:any) => {
        console.log(data)
				observer.next(data);
			});

			return () => {
				this.socket.disconnect();
			};
		});
  }
  
  getChatList(userId: string = null): Observable<ChatListResponse> {
    console.log(userId)
		if (userId !== null) {
			this.socket.emit('chat-list', { userId: userId });
		}
		return new Observable(observer => {
			this.socket.on('chat-list-response', (data: ChatListResponse) => {
				console.log(data)
				observer.next(data);
			});
			return () => {
				this.socket.disconnect();
			};
		});
	}

	logout(userId: string): Observable<Auth> {
		this.socket.emit('logout', userId);
		return new Observable(observer => {
			this.socket.on('logout-response', (data: Auth) => {
				observer.next(data);
			});
			return () => {
				this.socket.disconnect();
			};
		});
	}

}

