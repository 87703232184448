<div>
    <h2 class="form-heading">&nbsp;&nbsp;Reset Your Password</h2>
    <div>
        <div class="text-center" style="margin-bottom: 30px;">
            <!-- <button class="btn btn-outline-primary text-center" style="margin-right:1rem" (click)="back()"><i class="fa fa-home" style="margin-right:0.5rem"></i>Home</button> -->
        </div>
        <div class="container" style="background-color:#FFF7E9;">
            <form [formGroup]="form" class="form" novalidate>
                <div class="card-body"  style="background-color:#FFF7E9; border: 1px solid #ddd;" *ngIf="is_mobile">
                    <div class="row" >
                        <div class="col-md-4">
                            <label class="control-label">
                                Mobile<span class=' text-danger'>*</span>
                            </label>
                        </div>
                        <div class="col-md-4">
                            <input type="text" class="form-control" formControlName="mobile"
                                [class.is-invalid]="f.mobile.invalid && validateForm">
                            <div class="invalid-feedback" *ngIf="f.mobile.errors">
                                <div *ngIf="f.mobile.errors.pattern">
                                    Invalid middle_name.
                                </div>
                                <div *ngIf="f.mobile.errors.maxLength">
                                    Max Length is 25 characters
                                </div>
                            </div>
                        </div>
                        <div class="col-md"></div>
                    </div><br><br>
                    <!--modal-->
                    <div>
                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"
                            tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog modal-sm">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="staticBackdropLabel">
                                            Mobile OTP Verification
                                        </h5>
                                        <button type="button" id="close" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        <form class="form" [formGroup]="otpForm" novalidate>
                                            <div class="form-group">
                                                <label for="otp" class="form-label">Enter OTP sent to your mobile
                                                    number</label>
                                                <input type="text" class="form-control" formControlName="otp"
                                                    [class.is-invalid]="g.otp.invalid" />
                                                <div class="invalid-feedback" *ngIf="g.otp.errors">
                                                    <div *ngIf="g.otp.errors.required">OTP is required.</div>
                                                    <div *ngIf="g.otp.errors.incorrect">Invalid OTP.</div>
                                                </div>
                                            </div>
                                            <br />
                                            <button class="btn btn-outline-primary btn-sm" (click)="sendMobileOTP()"
                                                data-bs-target="#staticBackdrop">
                                                Resend
                                            </button>
                                            <button class="btn btn-outline-success btn-sm" style="float: right"
                                                (click)="validateOtp()">
                                                Submit
                                            </button>
                                        </form>
                                    </div>
                                    <!-- <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Understood</button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="btn btn-primary btn-block my-4" [routerLink]="['/login']">
                            Cancel
                        </button>
                        <button type="button" class="btn btn-primary btn-block my-4" (click)="checkCustomer()"
                            style="float: right;">Validate</button>
                        <button data-bs-toggle="modal" id="modalotp" data-bs-target="#staticBackdrop"
                            style="display: none;"></button>
                    </div>
                </div>

                <div *ngIf="is_active">
                    <div class="form-group row mt-2">
                        <div class="col-md-4">
                            <label class="control-label">New Password<span class="required">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" type="password" formControlName="new_password"
                                placeholder="new password" [class.is-invalid]="validateForm && f.new_password.errors" />

                            <div class="invalid-feedback" *ngIf="f.new_password.errors">
                                <div *ngIf="f.new_password.errors.required">Please enter new password.</div>
                                <div *ngIf="f.new_password.errors.minlength">New password is should be atlease 5
                                    characters.</div>
                                <div *ngIf="f.new_password.errors.maxlength">New password is too long.</div>
                            </div>

                        </div>
                    </div>

                    <div class="form-group row mt-2">
                        <div class="col-md-4">
                            <label class="control-label">Confirm Password<span class="required">*</span></label>
                        </div>
                        <div class="col-md-4">
                            <input class="form-control" type="password" placeholder="confirm password"
                                [class.is-invalid]="validateForm && f.confirm_password.errors"
                                (change)="validateConfirmPass()" formControlName="confirm_password" />
                            <div class="invalid-feedback" *ngIf="f.confirm_password.errors">
                                <div *ngIf="f.confirm_password.errors.required">Please enter new password again here.
                                </div>
                                <div *ngIf="f.confirm_password.errors.not_match">New password not matching.</div>
                            </div>

                        </div>
                    </div>
                    <br>

                    <div class="col-md-6">
                        <button type="button" class="btn btn-primary btn-sm mt-2" [routerLink]="['/login']">
                            Cancel
                        </button>
                        <button class="btn btn-primary btn-sm mt-2" style="float: right;margin-right: 15px;"
                            (click)="sendPassword()" [routerLink]="['/login']">Change Password</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <br>
</div>
<br><br>