import { Injectable } from '@angular/core';
import {io} from 'socket.io-client';
import { Observable } from 'rxjs';

import { Message} from '../../../app/interfaces/message'
import { MessagesResponse } from '../../../app/interfaces/messages-response'
import { ChatListResponse } from '../../../app/interfaces/chat-list-response'
import { LocalStorageService } from '../../services/local-storage.service'
import { JsonPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class ChatService {
    private url = 'http://localhost:4000/';
    private socket : any;   

    constructor(private localService : LocalStorageService) {
    }

  connectSocket(userId:any){
    this.socket = io(this.url,{query:{userId:"0000000"+userId}});
  }

  sendMessage(message : any) {
    this.socket.emit('add-message', message);
  }

  receiveMessages(): Observable<Message> {
		return new Observable(observer => {
			this.socket.on('add-message-response', (data:any) => {
        console.log(data)
				observer.next(data);
			});

			return () => {
				this.socket.disconnect();
			};
		});
  }
  
  getChatList(userId: string = null): Observable<ChatListResponse> {
    console.log(userId)
		if (userId !== null) {
			this.socket.emit('chat-list', { userId: userId });
		}
		return new Observable(observer => {
			this.socket.on('chat-list-response', (data: ChatListResponse) => {
				observer.next(data);
			});
			return () => {
				this.socket.disconnect();
			};
		});
	}

}

