<!-- <div> -->
<div class="row">
	<!-- <div class="col"></div> -->
	<div class="col-md-4"></div>
	<div class="col-md-4">
		
			<div id="viewProfile" class="chat-text1" style="z-index: 500 !important;">

				<div class="chat-box1">
				  <div class="chat-head1">
					
						<div class="row" > 
							<div class="col-md-2">
								<img class="picture" src="assets/images/profile.png" alt="avatar 1"
								style="width:45px;border-radius: 50%;padding-top: 3px;padding-left: 4px;">
							</div>
							<div class="col-md-8">
								<h6>{{toUserName}}</h6>
								<p class="form-text text-muted text-muted-color" style="color: #ffffff !important;">{{isOnline ? 'Online' : 'Offline'}}</p>
							<!-- ///<span [ngClass]="user.online === 'Y' ? 'online' : 'offline'"> </span> -->
							
							</div>
							<div class="col-md-2">
								<span title="Expand Arrow" width="16" style="float: right;" (click)="close()"><i class="fa fa-close" style="color: white;width: 30px;margin-top: 5px;"></i></span>
							</div>

						</div>
					
					<!-- <img class="avatar" src="assets/images/profile.png" alt="avatar 1"
					style="width: 45px; height: 100%;">
					<h5>{{toUserName}}</h5>
					  <span title="Expand Arrow" width="16" style="float: right;" (click)="close()"><i class="fa fa-close" style="color: white;width: 30px;margin-top: 5px;"></i></span> -->
				  </div>
				  <div class="card" style="width: 100%;">
				  <div class="chat-body1">
	
					<section style="background-color: #FFE7D0;">
						<!-- <div class="container py-5"> -->
						  <!-- <div class="row d-flex justify-content-center">
							<div class="col-md-12 col-lg-12 col-xl-12"> -->
							  <!-- <div class="card" style="width: 100%;"> -->
							
								<div class="card-body"  #scrollMe [scrollTop]="scrollMe.scrollHeight" style="position: relative; height: 335px;overflow-y: auto;">
								  
								  <div>
								  <div  *ngFor = "let date of dates;let i = index;">
									<p id="hl" class="form-text text-muted text-muted-color" >{{date == chkCurrentDate ? 'Today' : date }}</p>
								<div *ngFor ="let data of datas[i]" style="overflow-y: auto;">	
								  <div *ngIf="fromUserId != data.fromUserId" class="d-flex flex-row justify-content-start">
									<img class="picture" src="assets/images/profile.png" alt="avatar 1"
									style="width:45px;height:45px;border-radius: 50%;padding-top: 3px;padding-left: 4px;">
									<div>
									  <p class="small p-2 ms-3 mb-3 rounded-3" style="background-color: #5F9DF7; color: #fff;">{{data.message}}<br><span class="form-text" style="color:#ddd">{{data.messageTime}}</span> </p>
									</div>
								
								  </div>
								
								  <div *ngIf="fromUserId == data.fromUserId" class="d-flex flex-row justify-content-end mb-4 pt-1">
									<div>
									  <p class="small p-2 me-3 mb-3 rounded-3" style="background-color: #1746A2; color:#fff"> {{data.message}}<br><span class="form-text" style="color:#ddd">{{data.messageTime}}</span> </p>
									
									</div>
									<img class="picture" [src]="fromPicture != '' ? fromPicture : 'assets/images/profile.png'" alt="avatar 1"
									style="width:45px;height:45px;border-radius: 50%;padding-top: 3px;padding-left: 4px;">
								  </div>
								  </div>
								  </div>
								  </div>
								</div>
								  
								<div class="card-footer text-muted d-flex justify-content-start align-items-center p-3">
								  <div class="input-group mb-0">
									<input type="text" class="form-control" (keyup.enter)="sendMessage($event)" placeholder="Type message" [(ngModel)]="message_text" />
									  <button  (click)="sendMessage($event)" class="btn btn-warning" type="submit" id="button-addon2" style="padding-top: .55rem;">
									  Send
									</button>
								  </div>
								</div>
							  <!-- </div>  -->
							<!-- </div>
						  </div> -->
						<!-- </div> -->
					  </section>
					  <br>
				  </div>
				  </div>
				</div>
			  </div>  
			  
	</div>
	<div class="col-md-4">
		<div class="right-menu">
			<div class="chat-text">

				<div class="chat-box">
				  <div class="chat-head">
					  <div class="row" *ngIf = "!newMsg">
						  <div class="col-md-2" (click)="toggle()">
							<img class="picture" src="assets/images/profile.png" alt="avatar 1"
							style="width:45px;border-radius: 50%;padding-top: 3px;padding-left: 4px;">
						  </div>
						  <div class="col-md-8" (click)="toggle()">
							<p>Messaging</p>
						  </div>
						  <div class="col-md-2" (click)="toggle()">
							<span title="Expand Arrow" width="16" style="float: right;"><i [class]="show ? 'fa fa-angle-down' : 'fa fa-angle-up'" style="color: white;width: 30px;margin-top: 5px;"></i></span>
						  </div>
					  </div>
					  <div class="row" *ngIf="newMsg">
						<div class="col-md-2 chatCont"  (click)="toggle()">
							<img class="picture" src="assets/images/profile.png" alt="avatar 1"
							style="width:45px;border-radius: 50%;padding-top: 3px;padding-left: 4px;">
						  <!-- <img class="chatImg" [src]="fromPicture!='' ? fromPicture : 'assets/images/profile.png'" alt="avatar 1" style="width: 40px;height: 80%;"> -->
					  
						</div>
						<div class="col-md-8" (click)="toggle()">
						  <h6>Messaging</h6>
						</div>
						<div class="col-md-1" (click)="toggle()">
							<div class="col-md-2 chatCont" >
							<span><i class="fa fa-envelope" style="color: #ffffff;"></i></span>
							<div class="chatBadge"></div>
							</div>
						  </div>
						<div class="col-md-1" (click)="toggle()">
						  <span title="Expand Arrow" width="16" style="float: right;"><i [class]="show ? 'fa fa-angle-down' : 'fa fa-angle-up'" style="color: white;width: 30px;margin-top: 5px;"></i></span>
						</div>
					</div>
					<!-- <p>Messaging</p> -->
					<!-- <h5>Messaging</h5> -->
					  <!-- <span title="Expand Arrow" width="16" (click)="toggle()" style="float: right;"><i [class]="show ? 'fa fa-angle-down' : 'fa fa-angle-up'" style="color: white;width: 30px;margin-top: 5px;"></i></span> -->
				  </div>
				  <div class="card">
				  <div id="a" class="chat-body" style="overflow-y: auto;background-color: #FFE7D0;">
					<div class="input-group md-form form-sm form-2 pl-0">
						<input class="form-control my-0 py-1 red-border" type="text" [(ngModel)]="searchId" placeholder="Search" aria-label="Search" (input)="getOriginal()">
						<div class="input-group-append" >
						  <span class="input-group-text red lighten-3" (click)="searchUser()" style="cursor: pointer; background-color: #1746A2; color:white; height:2.2rem" id="basic-text1"><i style="height:15px;width:15px;" class="fa fa-pencil-square-o"
							  ></i></span>
						</div>
					  </div>
					<!-- <ul class="user-list">
						<li *ngFor="let user of latestUsers" 
							(click)="selectUser(user)" >
						<img class="avatar" src="assets/images/profile.png" alt="avatar 1"
						style="width: 45px; height: 80%;"> 
							<span style="margin-bottom: 5px;">&nbsp;{{ user.firstname}}</span>
							<span [ngClass]="user.online === 'Y' ? 'online' : 'offline'"> </span>
							<p class="form-text text-muted text-muted-color" style="margin-left: 55px;margin-bottom:10px !important;overflow: hidden;">&nbsp;{{user.message}}</p>
						</li>
					</ul> -->
					<ul class="user-list">
						<li class="row"  *ngFor="let user of latestUsers" 
						(click)="selectUser(user)" >
							<div class="col-md-2">
								<img class="picture" src="assets/images/profile.png" alt="avatar 1"
						style="width:50px;border-radius: 50%;">
							</div>
							<div class="col-md-10">
								<label *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId == fromUserId" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
								<label *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId != fromUserId" style="margin-bottom: 5px;"><b>&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</b></label>
								<label *ngIf="user.newMsg != undefined && user.newMsg=='N'" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
								<label *ngIf="user.newMsg == undefined" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
							<!-- ///<span [ngClass]="user.online === 'Y' ? 'online' : 'offline'"> </span> -->
							<p *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId == fromUserId" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
							<p *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId != fromUserId" style="white-space: nowrap;overflow: hidden;"><b>&nbsp;{{user.message}}</b></p>
							<p *ngIf="user.newMsg != undefined && user.newMsg=='N'" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
							<p *ngIf="user.newMsg == undefined" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
							</div>
						</li>
					</ul>
					<!-- <div *ngFor="let message of chatListUsers">
						<p (click)="selectUser(message)">{{message.username}}</p>
					</div> -->
				  </div>
				  </div>
				</div>
			  </div>  
			  </div>
	</div>
</div>
<!-- </div> -->

<!-- mobile view -->
<!-- <div class="mobile-view">
	<br>
	<br>
	<div>
				  <div class="card">
				  <div style="overflow-y: auto;background-color: #ddd;">
					<div class="input-group md-form form-sm form-2 pl-0">
						<input class="form-control my-0 py-1 red-border" type="text" [(ngModel)]="searchId" placeholder="Search" aria-label="Search" (input)="getOriginal()">
						<div class="input-group-append" >
						  <span class="input-group-text red lighten-3" (click)="searchUser()" style="cursor: pointer;" id="basic-text1"><i style="height:15px;width:15px;" class="fa fa-pencil-square-o"
							  ></i></span>
						</div>
					  </div>
					
					<ul>
						<li class="row" *ngFor="let user of latestUsers" 
						(click)="selectUser(user)" >
							<div class="col-md-2">
								<img class="picture" src="assets/images/profile.png" alt="avatar 1"
						style="width:50px;border-radius: 50%;">
							</div>
							<div class="col-md-10">
								<label *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId == fromUserId" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
								<label *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId != fromUserId" style="margin-bottom: 5px;"><b>&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</b></label>
								<label *ngIf="user.newMsg != undefined && user.newMsg=='N'" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
								<label *ngIf="user.newMsg == undefined" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
							
							<p *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId == fromUserId" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
							<p *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId != fromUserId" style="white-space: nowrap;overflow: hidden;"><b>&nbsp;{{user.message}}</b></p>
							<p *ngIf="user.newMsg != undefined && user.newMsg=='N'" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
							<p *ngIf="user.newMsg == undefined" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
							</div>
						</li>
					</ul>
				  </div>
				  </div>
				
	</div>
</div> -->
