import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from '../services/local-storage.service';
import { ApiService } from '../services/api.service';
import { ENDPOINTS } from '../app.config';
import { NotificationService } from '../services/notification.service';
import { Router } from '@angular/router';
import { DOMAIN_NAME, USER_ID } from 'src/app/app.config';
import { Base64 } from 'js-base64';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  providers: [DatePipe]
})
export class MyAccountComponent implements OnInit {
  mobile_no: any;
  uid: any;
  email: any;
  first_name: any;
  latest_date: string;

  constructor(private formBuilder: FormBuilder,
    private localService: LocalStorageService,
    private apiService: ApiService,
    private notificationService: NotificationService,
    private router: Router,
    private datePipe: DatePipe) { }

  collapse = {
    showPassword: false,
    showFeedback: false
  }

  feedBackForm: FormGroup;
  passwordForm: FormGroup;
  otpForm: FormGroup;
  showOtp: boolean = false;
  validatePassForm: boolean = false;
  validateOtpForm: boolean = false;
  validateFeedForm: boolean = false;
  mobileNumber: any;
  otpResendDuration: any;

  modules = ['Event', "Connections", "My profile", "Invitation", "Registration", "Login"]

  ngOnInit(): void {
    this.memberData()

    this.feedBackForm = new FormGroup({
      module: this.formBuilder.control('', [Validators.required]),
      feedback: this.formBuilder.control('', [Validators.required, Validators.minLength(3), Validators.maxLength(300)])
    })

    this.otpForm = new FormGroup({
      otp: this.formBuilder.control('', [Validators.required])
    })

    this.passwordForm = new FormGroup({
      new_password: this.formBuilder.control('', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]),
      confirm_password: this.formBuilder.control('', [Validators.required, Validators.minLength(5), Validators.maxLength(20)])
    })

    this.getUser().then((resp: any) => {
      if (resp) {
        this.mobileNumber = resp.mobile
      }
    })
  }

  goToProfile() {
    this.router.navigate(['/member/myprofile/' + this.localService.getMemberId()])
  }

  savePassword() {
    if (this.passwordForm.valid) {
      this.validatePassForm = false;
      this.sendOtptoUser();
      this.notificationService.showSuccess("OTP sent to this number " + this.mobileNumber.toString(), "Otp sent!")
      this.showOtp = true;
    }
    else {
      this.validatePassForm = true;
    }
  }

  sendPassword() {
    if (this.otpForm.valid) {
      this.verifyOtp().then((resp) => {
        if (resp) {
          let request = { "domain_name": this.localService.getDomain(), "user_id": this.localService.getUserId(), "payload": { "password_details": { "new_password": this.passwordForm.value.new_password } }, "extras": { "find": { "member_id": this.localService.getMemberId() } } }

          this.apiService.postApiCall(ENDPOINTS.CHANGE_PASSWORD, request).subscribe((resp) => {
            if (resp && resp.success == 1) {
              this.notificationService.showSuccess("Password updated successfully", 'Updated');
              this.showOtp = false;
              this.passwordForm.reset({
                new_password: "",
                confirm_password: ""
              })

            }
            else {
              this.notificationService.showError('Something bad happened; Please try again', 'Error')
            }
          }, err => {
            if (err && err.error && err.error.statusCode === 403) {
              this.notificationService.showError('Session Time Out! Please login Again', 'Session time out')
              this.router.navigate([`/login`], { skipLocationChange: false });
            }
          })
        }
      }).catch(err => {
        this.notificationService.showError("Something bad happened; Please try agian!", 'error');
      })
    }
    else {
      this.validateOtpForm = true;
    }
  }

  sendFeedback() {
    console.log(this.feedBackForm.value.module)
    if (this.feedBackForm.valid) {
      let request = { "domain_name": this.localService.getDomain(), "user_id": this.localService.getUserId(), "payload": { "create_contactus": this.feedBackForm.value }, "extras": { "find": {} } }

      this.validateFeedForm = false;
      this.apiService.postApiCall(ENDPOINTS.SEND_FEEDBACK, request).subscribe((resp) => {
        if (resp && resp.success == 1) {
          console.log("feeedback",this.feedBackForm.controls["module"].value)
          this.notificationService.showSuccess("Thanks for your feedback", "success")
          this.emailsent()
          this.feedBackForm.reset({
            module: "", feedback: ""
          })
        }
        else {
          this.notificationService.showError("Something bad happened; Please try agian!", 'error');
        }
      }, err => {
        if (err && err.error && err.error.statusCode === 403) {
          this.notificationService.showError('Session Time Out! Please login Again', 'Session time out')
          this.router.navigate([`/login`], { skipLocationChange: false });
        }
      })
    }
    else {
      this.validateFeedForm = true;
    }
  }

  get p() { return this.passwordForm.controls }
  get f() { return this.feedBackForm.controls }
  get o() { return this.otpForm.controls }

  validateConfirmPass() {
    if (this.passwordForm.value.new_password != this.passwordForm.value.confirm_password) {
      this.passwordForm.controls.confirm_password.setErrors({ not_match: true })
    }
    else {
      this.passwordForm.controls.confirm_password.setErrors(null)
    }
  }

  changeCollapse(evt: any) {
    if (evt == 'password') {
      this.collapse.showPassword = !this.collapse.showPassword
      this.collapse.showFeedback = false;
      this.validatePassForm = false;
      this.validateFeedForm = false;
    }
    else {
      this.collapse.showFeedback = !this.collapse.showFeedback
      this.collapse.showPassword = false;
      this.validatePassForm = false;
      this.validateFeedForm = false;
    }
  }

  getUser() {
    return new Promise((resolve, reject) => {
      let request = { "domain_name": this.localService.getDomain(), "user_id": this.localService.getUserId(), "extras": { "find": { "uid": this.localService.getMemberId() }, "pagination": true, "paginationDetails": { "limit": 0, "pageSize": 10 }, "sorting": true, "sortingDetails": { "email": -1 } } }

      this.apiService.postApiCall(ENDPOINTS.GET_MEMBER, request).subscribe((resp) => {
        if (resp && resp.success == 1) {
          resolve(resp.result.result[0])
        }
        else {
          resolve(null)
        }
      }, err => {
        resolve(null)
      })

    })
  }
  sendOtptoUser() {
    let requestData = {
      "domain_name": this.localService.getDomain(),
      "organization_id": this.localService.getUserId(),
      "store_id": 1,
      "data": {
        "otp": {
          "countrycode": "+91",
          "mobile": this.mobileNumber,
          "otppurpose": "changepassword"
        }
      }
    }
    this.apiService.postApiCall(ENDPOINTS.SEND_OTP_MOBILE, requestData).subscribe(
      resp => {


        //set resend duration
        if (resp && resp.success === 1) {
          this.otpResendDuration = new Date().getTime() + 5 * 60 * 1000;
        }

      },
      err => {
        if (err && err.error && err.error.statusCode === 403) {
          this.notificationService.showError('Session Time Out! Please login Again', 'Session time out')
          this.router.navigate([`/login`], { skipLocationChange: false });
        }
      })
  }

  resendCode() {


    if (this.otpResendDuration === undefined) {
      this.sendOtptoUser();
      window.scrollTo(0, 0);
      this.notificationService.showInfo('OTP has been sent Successfully!', 'otp');
    }
    else if (this.otpResendDuration < new Date().getTime()) {
      this.sendOtptoUser();
      window.scrollTo(0, 0);
      this.notificationService.showInfo('OTP has been sent Successfully!', 'otp');
    }
    else {
      window.scrollTo(0, 0);
      this.notificationService.showError("OTP already sent to this number " + this.mobileNumber.toString(), 'otp');
    }
  }

  verifyOtp() {
    return new Promise((resolve, reject) => {
      let requestData = {
        "domain_name": this.localService.getDomain(),
        "organization_id": this.localService.getUserId(),
        "store_id": '',
        "data": {
          "otp": {
            "userotp": this.otpForm.value.otp,
            "mobile": this.mobileNumber
          }
        }
      }

      this.apiService.postApiCall(ENDPOINTS.VERIFY_OTP_MOBILE, requestData).subscribe((resp) => {
        if (resp && resp.success == 1) {
          resolve(true)
        }
        else if (resp && resp.success == 0) {
          this.notificationService.showError(resp.message, 'error')
          resolve(false);
        }
        else {
          resolve(false);
        }
      }, err => {
        resolve(false)
      })

    })

  }
  memberData() {
    const requestBody = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "extras": {
        "find": {
          "uid": this.localService.getMemberId()
        }
      }
    }
    this.apiService.postApiCall(ENDPOINTS.GET_MEMBER, requestBody).subscribe(
      resp => {
        const data1 = resp.result
        this.uid = data1.result[0].uid
        this.mobile_no = data1.result[0].mobile
        this.email = data1.result[0].email
        this.first_name = data1.result[0].is_an[0].first_name
      },
      err => {
        console.log("Error: ", err);
      }
    );
  }
  emailsent() {
    let now = new Date();
    this.latest_date = this.datePipe.transform(now, 'MMM d, y, h:mm:ss a');
    console.log(this.latest_date)
  let template = `<div>
  <tbody>
  <tr>
  <td class="m_-150439456461445417td"
  style="width:600px;min-width:600px;font-size:0pt;line-height:0pt;padding:0;margin:0;font-weight:normal">
  <table width="100%" cellspacing="0" cellpadding="0" border="0">
  <tbody>
  <tr>
  <td class="m_-150439456461445417mpx-10">
  <table width="100%" cellspacing="0" cellpadding="0" border="0">
  <tbody>
  <tr>
  <td class="m_-150439456461445417l-grey"
  style="font-size:12px;line-height:16px;font-family:'PT Sans',Arial,sans-serif;min-width:auto!important;color:#6e6e6e;text-align:right;padding-top:20px;padding-bottom:20px">
  </td>
  </tr>
  </tbody>
  </table>
  <table width="100%" cellspacing="0" cellpadding="0" border="0">
  <tr>
  <td style="border-radius:10px 10px 0 0" bgcolor="#ffffff">
  <table width="100%" cellspacing="0" cellpadding="0"
  border="0">
  <tbody>
  <tr>
  <td class="m_-150439456461445417mpx-15"
  style="padding-left:50px;padding-right:50px">
  <table width="100%" cellspacing="0"
  cellpadding="0" border="0">
  <tbody>
  <tr>
  <td style="padding-bottom:20px">
  <table width="100%"
  cellspacing="0"
  cellpadding="0"
  border="0">
  <tbody>
  <tr>
  <td
  style="font-size:20px;line-height:40px;color:#282828;font-family:'PT Sans',Arial,sans-serif;min-width:auto!important;text-align:center;padding-bottom:0">
  <u></u>
  This feedback is given by <b style="color:blue">${this.first_name}</b> on <b style="color:blue">${this.latest_date}</b>,following are the <b style="color:blue">${this.first_name}</b> details
  <u></u>
  </td>
  <tr>
  <td
  style="font-size:20px;line-height:40px;color:#282828;font-family:'PT Sans',Arial,sans-serif;min-width:auto!important;text-align:center;padding-bottom:0">
  <u>uid: <b style="color:blue">${this.uid}</b></u><br>
  
  <u>email_id: <b style="color:blue">${this.email}</b></u><br>
  
  <u>mobile: <b style="color:blue">${this.mobile_no}</b></u>
  </td>
  </tr>
  <tr>
  <td
  style="font-size:20px;line-height:40px;color:#282828;font-family:'PT Sans',Arial,sans-serif;min-width:auto!important;text-align:center;padding-bottom:0">
  <u></u>
  the feedback is provided on the <b style="color:blue">${this.feedBackForm.controls["module"].value}</b> module.<br>
  Feedback : <b style="color:blue">${this.feedBackForm.controls["feedback"].value}</b>
  <u></u>
  </td>
  </tr>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
  <table width="100%" cellspacing="0" cellpadding="0"
  border="0">
  <tbody>
  <tr>
  <td class="m_-150439456461445417mpx-15"
  style="border-radius:0 0 10px 10px;padding:50px"
  bgcolor="#1746A2">
  <table width="100%" cellspacing="0"
  cellpadding="0" border="0">
  <tbody>
  <tr>
  <td class="m_-150439456461445417l-white"
  style="font-size:14px;font-family:'PT Sans',Arial,sans-serif;min-width:auto!important;line-height:24px;text-align:center;color:#ffffff;padding-bottom:20px">
  </td>
  </tr>
  <tr>
  <td class="m_-150439456461445417l-white"
  style="font-size:14px;font-family:'PT Sans',Arial,sans-serif;min-width:auto!important;line-height:24px;text-align:center;color:#ffffff;padding-bottom:20px">
  <u></u>
  </td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody>
  </table>
  </td>
  </tr>
  </tbody></div>`
    let temp = Base64.encode(template).toString();
    if (temp != null) {
      const reqBody =
      {
        "communication": {
          "email": ["presentsirhelp@gmail.com"],
          "subject": `Feedback from our user ${this.first_name} on Presentsir`,
          "type": "html",
          "content": temp
        }
      }
      this.apiService.postApiCall(ENDPOINTS.INVITEMAIL, reqBody).subscribe(
        resp => {
          console.log(resp)
        })
    }
  }
}
