import { environment } from 'src/environments/environment';

export const API_URL = environment.apiURL;
export const CHATAPI = environment.apiURL + ":4000/"

export const DOMAIN_NAME = "https://presentsir.com";
export const USER_ID = 17;

export const EXCEL_TEMPLATE:any = {
    School : "https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Presentsir/bulk-invite/School-template.xlsx",
    College : "https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Presentsir/bulk-invite/College-template.xlsx",
    Certification : "https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Presentsir/bulk-invite/Certification-template.xlsx",
    Profession : "https://ecbee-labes-images1.s3.ap-south-1.amazonaws.com/Presentsir/bulk-invite/Profession-template.xlsx"

}

export const ENDPOINTS = {

    API_URL: API_URL,
    CREATE_MEMBER : API_URL + ":3030/create",
    GET_LOCALITY: API_URL + ":9593/get",
    GET_ALL_MEMBER: API_URL + ":3032/getall",
    INVITEE: API_URL + ":3031/create",
    GET_MOBILE_VALIDATION: API_URL + ":9704/validateMobileNumber",
    REG_SUBMIT: API_URL + ":5515/registration/submit",
    REG_APPROVE: API_URL + ":5515/registration/approve",
    REG_REJECT: API_URL + ":5515/registration/reject",
    GET_INVITEE: API_URL + ":3033/getbyuid",
    GET_ALL_INVITEE: API_URL + ":3033/getall",
    GET_ALL_APPROVALS: API_URL + ":9705/approvals/getall",
    GET_APPROVALS: API_URL + ":9705/approvals/getbyid",
    GET_ALL_APPROVAL_HISTORY: API_URL + ":9706/approvalshistory/getall",
    GET_APPROVAL_HISTORY: API_URL + ":9706/approvalshistory/getbyid",
    MEM_ACTIVATION: API_URL + ":3030/memberActivation",
    SEND_OTP_MOBILE: API_URL + ":9703/communication/GenerateOtpSMS",
    VERIFY_OTP_MOBILE: API_URL + ":9703/communication/verifyotp",
    REGISTRATION: API_URL + ":5540/registration",
    PERSIONAL_INFO: API_URL + ":5540/personal",
    ACADAMIC_SCHOOL: API_URL + ":5540/school",
    ACADAMIC_COLLEGE: API_URL + ":5540/college",
    PROFESSIONAL_INFO: API_URL + ":5540/professional",
    INTERESTS_INFOR: API_URL + ":5540/interests",
    GET_MEMBER: API_URL + ":9570/getbyuid",
    CERTIFICATION:API_URL + ":5540/certification",
    SCHOOL:API_URL + ":5540/school",
    COLLEGE:API_URL + ":5540/college",
    PROFESSION:API_URL + ":5540/profession",


    PROFILE_COMPLETENESS: API_URL + ":9570/profilecomplete",
    CUSTOMER_CREATE: API_URL + ":5684/customer/create",
    VERIFY_EMAIL: API_URL + ":9702/verifyEmail",
    CHECK_INVITEE: API_URL + ":9628/checkinvitee",
    CHECK_MEMBER: API_URL + ":9628/checkmember",
    EVENT_CATEGORY_GETALL: API_URL + ":9636/eventcategory/getall",
    FILTER_CATEGORY: API_URL + ":9630/filter",
    UPDATE_REGISTRATION_STATUS : API_URL + ':5540/update',
    UPDATE_INTEREST : API_URL + ':5540/updateinterests',
    EVENT_CREATE : API_URL + ':5619/event/create',
    EVENT_SEARCH : API_URL + ':5619/event/search',
    EVENT_UPDATE : API_URL + ':5619/event/update',
    EVENT_DELETE : API_URL + ':5619/event/delete',
    EVENT_GETALL : API_URL + ':9634/event/getall',
    EVENT_GETBYID : API_URL + ':9634/event/getbyid',
    MEMBER_APPROVAL : API_URL + ':5682/memberapproval',
    CHECK_FOR_APPROVAL :  API_URL + ':9681/checkforapproval',
    GET_CATEGORY : API_URL + ':9636/eventcategory/getall',
    GET_INTREST : API_URL + ':9627/interests/getall',
    CHANGE_PASSWORD : API_URL + ':5684/customer/updatepassword',
    UPDATE_DETAILS: API_URL + ':5684/customer/updatecustomer',
    TO_INVITE : API_URL + ':5541/invitee',
    INVITE_MEMBER : API_URL + ':9701/inviteMember',
    INVITATION : API_URL + ':5541/invitation',
    SEND_SMS : API_URL + ':9703/communication/sendsms',
    SMS : API_URL + ':5541/sms',
    INVITE_UPDATE : API_URL + ':5540/update',
    GETALL_APPROVALS : API_URL + ':9571/getbyuid',
    GET_MEMBERID : API_URL + ":9623/approval/getbyid",
    GET_MEMBERDATA : API_URL + ":9629/getmember",
    APPROVE : API_URL + ":5618/approval/create",
    APPROVE_UPDATE_MEMBER : API_URL + ":5540/update",
    APPROVE_UPDATE_INVITEE : API_URL + ":5540/update",
    ACTIVATE_UPDATE : API_URL + ":5540/update",
    GET_INSTITUTION : API_URL + ':9571/getinviteebyuid',
    SEND_FEEDBACK : API_URL + ':5606/contacteduser/create',
    LOGIN :API_URL + ':5202/login',
    GET_UIDS : API_URL + ':9632/getalleventsuids',
    GET_BY_UIDS : API_URL + ':9570/getbyuid',
    GET_INVITEE_BY_UID : API_URL + ':9571/getinviteebyuid',
    GET_INDIVIDUAL_CONNECTIONS : API_URL + ':9631/getindividualconnections',
    GET_CATEGORY_CONNECTIONS : API_URL + ':9631/getcategoryconnections',
    GET_UIDS_DATA : API_URL + ':9631/getuidsdata',
    GET_CUSTOMER_ALL : API_URL + ':9526/customer/getall',
    GETUID : API_URL  + ':9635/getbyuid',
    GETSCHOOL : API_URL + ':9573/getbyuid',
    GETCOLLEGE : API_URL + ':9574/getbyuid',
    GETPROFESSION : API_URL + ':9575/getbyuid',
    GETCERTIFICATE : API_URL + ':9576/getbyuid',
    INVITEMAIL: API_URL + ':9703/communication/sendmail',
    IMAGE_UPLOAD : API_URL + ':3333/imageupload/create',
    FAMILYSEND: API_URL + ':5540/family',
    FAMILY_GET: API_URL + ':9572/getbyuid',
    DELETE_INFO: API_URL +':5540/delete',
    MEMBERS_COUNT : API_URL + ':9683/getmembercount',
    INSTITUTE_COUNT:API_URL + ':9683/getinstitutescount',
    EMAIL :API_URL +':5541/email',
    GROUPTOINVITE : API_URL +':9680/groupstoinvite',
    CONNECTION : API_URL + ":9631/getconnections",
    LIST_APPROVALS: API_URL + ":9682/listapproval",
    GETROLE : API_URL + ":9570/getbyuid",
    APPROVAL_UPDATE: API_URL + ":5540/update",
    GET_INVITEE_BY_ID : API_URL + ":9571/getinviteebyuid",
    APPROVAL_HISTORY : API_URL + ':9682/approvalhistory',

    ACADEMIC_DETAILS : API_URL + ':9685/academics/getall',

    GETMSG : API_URL + ':4000/getmessages',
    GETMSG1 : API_URL + ':4000/getmessagesByName',
    LOGINSOCKET : API_URL + ':4000/login',
    GETUSERBYID : API_URL + ':4000/getuserbyid',
    GETUSERBYMOBILE : API_URL + ':4000/getuserbymobile',
    UPDATEPROFILE : API_URL + ':4000/updateprofile',
    CHATREGISTER : API_URL + ':4000/register',
    SELECT_STREAM : API_URL + ':9685/academics/getall',
    SELECT_UNIVERSITIES : API_URL + ':9684/universities/getall',

    EVENTNOTIFICATION: API_URL + ":9000/eventnotification",
    MEMBERNOTIFICATION: API_URL + ":9000/membernotification",
    CHATNOTIFICATION: API_URL + ":9000/chatnotification",

    CREATESUBSCRIBEDUSER: "http://localhost:9000/createsubscribeduser",
    // GETMSG : 'http://localhost:4000/getmessages',
    // GETMSG1 : 'http://localhost:4000/getmessagesByName',
    // LOGINSOCKET : 'http://localhost:4000/login',
    // GETUSERBYID : 'http://localhost:4000/getuserbyid',
    // GETUSERBYMOBILE : 'http://localhost:4000/getuserbymobile',
    // UPDATEPROFILE : 'http://localhost:4000/updateprofile',
    // CHATREGISTER : 'http://localhost:4000/register',
    
    BULK_INVITE_CREATE : API_URL + ':5680/bulk-invite',
    GROUP_TO_INVITE : API_URL + ':9680/groupstoinvite'
 }


    
