import { Component, OnInit } from '@angular/core';
import { ENDPOINTS, DOMAIN_NAME, USER_ID } from 'src/app/app.config';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  otpForm: FormGroup;
  validateForm: boolean = false;
  showOtp: boolean = false;
  member_id: any;
  is_active: boolean = false;
  is_mobile: boolean = true
  customer: any;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private notifyService: NotificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.otpForm = new FormGroup({
      otp: this.formBuilder.control('', [Validators.required])
    });
    this.form = new FormGroup({
      new_password: this.formBuilder.control('', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]),
      confirm_password: this.formBuilder.control('', [Validators.required, Validators.minLength(5), Validators.maxLength(20)]),
      mobile: this.formBuilder.control('', [Validators.required, Validators.maxLength(10), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$"), Validators.maxLength(10)]),
    });
  }
  get f() {
    return this.form.controls;
  }
  get g() {
    return this.otpForm.controls;
  }

  validateConfirmPass() {
    if (this.form.value.new_password != this.form.value.confirm_password) {
      this.notifyService.showError('please check your passord', 'Password Mismatched');
      this.form.controls.confirm_password.setErrors({ not_match: true })
    }
    else {
      this.form.controls.confirm_password.setErrors(null)
    }
  }



  checkCustomer() {
    let request = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "extras":
      {
        "find":
          { "phone_number": this.form.value.mobile }
      }
    }

    this.apiService.postApiCall(ENDPOINTS.GET_CUSTOMER_ALL, request).subscribe((resp) => {
      if (resp.status_code == 200 && resp.success == 1) {
        this.customer = resp.result.data.length
        console.log("mm",this.customer)
        if(this.customer > 0) {
          document.getElementById("modalotp").click()
          this.member_id = resp.result.data[0].member_id
          this.sendMobileOTP()
        }
        else if(this.customer == 0) {
          this.notifyService.showInfo('Mobile Number does not exist', 'Error')
        }
        else{
          this.notifyService.showError('Something bad happened; Please try again', 'Error')
        }
      }
      else {
        this.notifyService.showError('Something bad happened; Please try again', 'Error')

      }
    })
  }
  sendMobileOTP() {
    this.validateForm = false;
    const requestBody = {
      "data": {
        "otp": {
          "mobile": this.form.value.mobile,
          "otppurpose": "register"
        }
      }
    }
    this.apiService.postApiCall(ENDPOINTS.SEND_OTP_MOBILE, requestBody).subscribe(
      resp => {
        if (resp && resp.success == 1) {
          this.notifyService.showInfo('OTP Sent to your mobile number', 'Mobile Verification');
          console.log("OTP SENT", resp);
        }
        else {
          this.notifyService.showError('Something Went Wrong!', 'Mobile Verification');
          console.log("Invalid Mobile", resp);
        }
      })
  }
  validateOtp() {
    // document.getElementById('close').setAttribute('data-dismiss','modal');
    const requestBody = {
      "data": {
        "otp": {
          "userotp": this.otpForm.value.otp,
          "mobile": this.form.controls["mobile"].value
        }
      }
    }
    this.apiService.postApiCall(ENDPOINTS.VERIFY_OTP_MOBILE, requestBody).subscribe(
      resp => {
        if (resp && resp.success == 1) {
          this.is_active = true
          this.is_mobile = false
          console.log("OTP VERIFIED", resp);
          document.getElementById("close")?.click();
          // document.getElementById("close").click();
        }
        else {
          this.otpForm.controls["otp"].setErrors({ 'incorrect': true });
          console.log("WRONG OTP", requestBody, resp);
          this.notifyService.showWarning('Invalid OTP!', 'Mobile Verification');
        }
      },
      err => {
        this.notifyService.showError('Something Went Wrong!', 'Mobile Verification');
        console.log("Error", err);
        // this.submit();
        // document.getElementById("close")?.click();
      })

  }
  sendPassword() {
    if (this.form.valid) {
      if (this.form.value.new_password != this.form.value.confirm_password) {
        this.notifyService.showError('please check your passord', 'Password Mismatched');
        this.form.controls.confirm_password.setErrors({ not_match: true })
      }
        this.validateForm = false
        let request = {
          "domain_name": DOMAIN_NAME,
          "user_id": USER_ID,
          "payload": { "password_details": { "new_password": this.form.value.new_password } }, "extras": {
            "find": {
              "member_id": this.member_id
            }
          }
        }

        this.apiService.postApiCall(ENDPOINTS.CHANGE_PASSWORD, request).subscribe((resp) => {
          if (resp && resp.success == 1) {
            this.notifyService.showSuccess("Password updated successfully", 'Updated');
            this.showOtp = false;
            this.form.reset({
              new_password: "",
              confirm_password: ""
            })

          }
          else {
            this.notifyService.showError('Something bad happened; Please try again', 'Error')
          }
        }, err => {
          if (err && err.error && err.error.statusCode === 403) {
            this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
            this.router.navigate([`/login`], { skipLocationChange: false });
          }
        })
      }
    
  }

}
