<div class="container">
    <div class="row">
          <!----one----->
      <div class="col-12 col-sm-6 col-md-3 col-lg-3 designCard">
        <div class="profile">
          <div class="picture">
            <img class="img-fluid" src="assets/images/profile.png">
          </div>
          <div class="details">
            <h4 class="name">Raghuveerna</h4>
            <h4 class="title">Elait</h4>
          </div>
          <ul class="connect" style="height: 35px;">
            <button class="connect-btn"style="border-radius: 15px;background-color: transparent;border:1px solid blue">connect</button>

          </ul>
        </div>
      </div>
        <!----two----->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 designCard">
            <div class="profile">
              <div class="picture">
                <img class="img-fluid" src="assets/images/profile.png">
              </div>
              <div class="details">
                <h4 class="name">Ponraj</h4>
                <h4 class="title">Elait</h4>
              </div>
              <ul class="connect" style="height: 35px;">
                <button class="connect-btn"style="border-radius: 15px;background-color: transparent;border:1px solid blue">connect</button>
    
              </ul>
            </div>
          </div>
        <!----three----->
        <div class="col-12 col-sm-6 col-md-4 col-lg-3 designCard">
            <div class="profile">
              <div class="picture">
                <img class="img-fluid" src="assets/images/profile.png">
              </div>
              <div class="details">
                <h4 class="name">Meenal</h4>
                <h4 class="title">Elait</h4>
              </div>
              <ul class="connect" style="height: 35px;">
                <button class="connect-btn"style="border-radius: 15px;background-color: transparent;border:1px solid blue">connect</button>
    
              </ul>
            </div>
          </div>
      <!----four-->
      <div class="col-12 col-sm-6 col-md-4 col-lg-3 designCard">
        <div class="profile">
          <div class="picture">
            <img class="img-fluid" src="assets/images/profile.png">
          </div>
          <div class="details">
            <h4 class="name">Janaki</h4>
            <h4 class="title">Elait</h4>
          </div>
          <ul class="connect" style="height: 35px;">
            <button class="connect-btn"style="border-radius: 15px;background-color: transparent;border:1px solid blue">connect</button>

          </ul>
        </div>
      </div>
      <!------------->
      
    </div>
  </div>