<div *ngIf="!profile" class="row justify-content-between">
    <div class="col-sm-6">
        <h3 class="font-shadow">Connections</h3>
    </div>
    <div class="col-sm-6 search-bar">
      <form [formGroup]="searchForm" class="search-bar">
        <div class="d-flex justify-content-between search-input">
            <div class="input-group">
                <div class="input-group-prepend">
                    
                    <select class="form-control" id="customer_type" formControlName="category_type" style="background-color: #5F9DF7 !important; color:#fff !important; border-radius: 5px 0px 0px 5px;" [class.is-invalid]="validateForm && g.category_type.errors">
                    <option value="" selected>Select Category</option>
                    <option *ngFor="let type of allCategories" value={{type.category}}>{{type.category}}</option>
                  </select>
                  </div>
                <input type="text" placeholder="Search"
                    class="form-control search-form-input" (input)="getOriginal()" formControlName="search_term">
      
                <div class="input-group-btn"  >
                    <button class="btn btn-solid" style="background-color: #5F9DF7 !important;" (click)="getFilterCategory()">
                      <i class="fa fa-search search-icon" style="background-color: #5F9DF7 !important;" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </form>
    </div>

    <h4 class="font-shadow">{{isSearch ?'People you may know' : 'People in your group'}}</h4>
    <hr>
<!-- </div> -->
    <div *ngIf="!profile" class="row" >
          <!----one----->
      <div class="col-12 col-sm-6 col-md-3 col-lg-3 designCard"  *ngFor="let data of pageData;">
        
        <div class="card" style="margin-top:10px; background-color: #5F9DF7; border-radius: 5px;">
        <div class="profile"  (click)="getProfileData(data)" style="cursor: pointer;margin:5px; border-radius: 5px">
          <div>
            <img class="picture" style="border-radius: 5px;" [src]="data.picture != '' ? data.picture : 'assets/images/profile.png'">
          </div>
          <div class="details">
            <h4 class="name">{{data.first_name}}</h4>
            <h4 class="title">{{data.email}}</h4>
            <h4 class="title">{{data.dob != '' ? data.dob : '-'}}</h4>
            
          </div>
          <ul class="connect connct-ul">
            <button class="connect-btn connct-btn" >Connect</button>

          </ul>
        </div>
        </div>
      </div>
      
      </div>

  </div>
  <app-pagination *ngIf="!profile" style="float: right;"  [totalRecords]="pageCount" [recordsPerPage]="10" (onPageChange)="pageChange($event)"></app-pagination>
  <div *ngIf="!profile">
      <br>
      <br>
      <br>
      <br>
    </div>
      <div *ngIf="profile">
        <div class="container">
          <h4 style="text-align: center;" class="font-shadow">Personal Info</h4>
          <hr>
          <div class="card">
          <div class="card-header">
              <div class="row justify-content-between">
                  <div class="col">
                      <img [src]="profileData.picture != '' ? profileData.picture : 'assets/images/profile.png'" style="width:60px;height:60px;border-radius: 50%;" alt="...">
                  </div>
                  <div class="col">
                      <h4>{{profileData.first_name}}</h4>
                      <p>{{profileData.email}}</p>
                  </div>
                  <div class="col"></div>
                  <div class="col"></div>
                  <div class="col"></div>
                  <!-- <div class="col"></div> -->
              </div>
          </div>
          <div class="card-body">
            <p *ngIf="profileData.school != undefined || profileData.college != undefined || profileData.certification != undefined"><i class="fa fa-graduation-cap"></i>&nbsp;<b>Education</b></p>
              <div *ngIf="profileData.school != undefined && profileData.school.length" class="row">
                  <div *ngFor="let data of profileData.school">
                    <p *ngIf="data.school_name !=''"><b>- Studied in</b> {{data.school_name}}</p>
                    <p *ngIf="data.address !=''"><b>- Located at</b> {{data.address}}</p>
                    <p *ngIf="data.class !=''"><b>- Studied in class</b> {{data.class}}</p>
                    <p *ngIf="data.section !=''"><b>- Studied in section</b> {{data.section}}</p>
                    <p *ngIf="data.start_year !='' && data.end_year != ''"><b>- From </b>{{data.start_year}} <b>till</b> {{data.end_year}} </p>
                    
                  </div>
              </div>
              <div  *ngIf="profileData.college != undefined && profileData.college.length" class="row">
                <div  *ngFor="let data of profileData.college">
                    <p *ngIf="data.college_name !=''"><b>- Studied in</b> {{data.college_name}}</p>
                    <p *ngIf="data.address !=''"><b>- Located at</b> {{data.address}}</p>
                    <p *ngIf="data.degree_type !=''"><b>- Pursued </b> {{data.degree_type}} degree in {{data.course}}</p>
                    <p *ngIf="data.start_year !='' && data.end_year != ''"><b>- From </b>{{data.start_year}} <b>till</b> {{data.end_year}} </p>
                </div>
            </div>
            <div *ngIf="profileData.certification != undefined && profileData.certification.length" class="row">
                <div  *ngFor="let data of profileData.certification">
                    <p *ngIf="data.certification_course !=''"><b>- Completed certification in</b> {{data.certification_course}} <b>from</b> {{data.institution_name}}</p>
                    <p *ngIf="data.address !=''"><b>- Located at</b> {{data.address}}</p>
                    <p *ngIf="data.start_year !='' && data.end_year != ''"><b>- From </b>{{data.start_year}} <b>till</b> {{data.end_year}} </p>
                </div>
            </div>
            <br *ngIf="profileData.profession != undefined && profileData.profession.length">
            <p  *ngIf="profileData.profession != undefined && profileData.profession.length"><i class="fa fa-industry"></i>&nbsp;<b>Professional Info</b></p>
              <div  *ngIf="profileData.profession != undefined && profileData.profession.length" class="row">
                <div  *ngFor="let data of profileData.profession">
                  <p *ngIf="data.firm_name !=''"><b>- Worked at </b>{{data.firm_name}}</p>
                    <p *ngIf="data.address !=''"><b>- Located at</b> {{data.address}}</p>
                    <p *ngIf="data.style !=''"><b>- Style</b> {{data.style}}</p>
                    <p *ngIf="data.start_year !='' && data.end_year != ''"><b>- From </b>{{data.start_year}} <b>till</b> {{data.end_year}} </p>
              </div>
              </div>
          
        <br>
        <!-- <p><b>Area of Interests</b></p>
        <div class="row">
          <div *ngFor="let interest of areaInterest">
            <p>- {{interest}}</p>
          </div> -->

      </div>
          </div>
          </div>
          <br>
  
          <div class="row" style="text-align: center;">
            <div class="col mobile-view"><button class="btn btn-primary btn-sm" style="margin-right: 0.5rem;" (click)="mobileChat()">Message {{profileData.first_name}}</button></div>
            <div class="col webView"><button class="btn btn-primary btn-sm" style="margin-right: 0.5rem;" (click)="chat()">Message {{profileData.first_name}}</button></div>
            <div><button class="col btn btn-primary btn-sm" (click)="back()">Back</button></div>
          </div>
          <br>
  </div>
        <!-- <form [formGroup]="form" class="form" novalidate>
            <h4 style="text-align: center;">Personal Info</h4>
            <hr>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            First Name :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.first_name}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Middle Name :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.middle_name}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Last Name :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.last_name}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Date of Birth :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.dob}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Mobile :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.mobile}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Email :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.email}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
            
    
                <div *ngIf="professional">
                <h4 style="text-align: center;">Professional</h4>
                <hr>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Entity Style :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.style}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Designation :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.designation}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Firm Name :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.firm_name}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Firm Address :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.address}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    </div>
    
    <div *ngIf = "school">
                <h4 style="text-align: center;">School</h4>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Level of Schooling :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.school_level}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            School Name :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.school_name}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Board :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.board}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Address :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.address}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Class :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.class}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Section :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.section}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Start Year :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.start_year}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            End Year :
                        </label>
                    </div>
                    <div class="col-md-5">
                        <p>{{profileData.end_year}}</p>
                    </div>
                    <div class="col-md"></div>
                </div><br>
                </div>
                <div *ngIf="college">
                <h4 style="text-align: center;">College</h4>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Type of Degree :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.degree_type}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            College Name :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.college_name}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Affialated To :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.affiliated_to}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Address :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.address}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Stream :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.stream}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Course :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.course}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Start Year :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.start_year}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            End Year :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.end_year}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
                </div>
                
                <div *ngIf="certificate">
    
    <h4 style="text-align: center;">Certification</h4>
    <hr>
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Certification Course Name :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.certification_course}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Institution Name :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.institution_name}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Affialated To :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.affiliated_to}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Address :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.address}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            Start Year :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.start_year}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
    
                <div class="row">
                    <div class="col-md-6">
                        <label class="control-label">
                            End Year :
                        </label>
                    </div>
                    <div class="col-md-5">
                        {{profileData.end_year}}
                    </div>
                    <div class="col-md"></div>
                </div><br>
                </div> 
                <br>
            </div>
                <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8" style="text-align: center;">
                        <button class="btn btn-outline-primary text-center" style="margin-right:0.5rem" (click)="back()">Back</button>
                    </div>
                    <div class="col-md-2"></div>
                </div>
                <br>
        </form>-->
    <!-- </div> -->

