<div>
    <div class="row justify-content-between">
        <div class="col">
            <h3 class="font-shadow">My Account</h3>
        </div>
    </div>
</div>
<hr>


<section>
    <p class="my-act-heading">what would you like to do:</p>


    <ul class="my-act">
        <li (click)="changeCollapse('password')"><a>Change Password <i class="fa fa-arrow-right"></i></a></li>

        <li *ngIf="collapse.showPassword" class="card" style="background-color:#FFF7E9">
            <form class="card-body" [formGroup]="passwordForm">
                
                <div class="form-group row mt-2">
                    <div class="col-md-2">
                        <label class="control-label">New Password<span class="required">*</span></label>
                    </div>
                    <div class="col-md-4">
                    <input class="form-control" type="password" formControlName="new_password" placeholder="new password" [class.is-invalid]="validatePassForm && p.new_password.errors"/>

                    <div class="invalid-feedback" *ngIf="p.new_password.errors">
                        <div *ngIf="p.new_password.errors.required">Please enter new password.</div>
                        <div *ngIf="p.new_password.errors.minlength">New password is should be atlease 5 characters.</div>
                        <div *ngIf="p.new_password.errors.maxlength">New password is too long.</div>
                    </div>

                    </div>
                </div>

                <div class="form-group row mt-2">
                    <div class="col-md-2">
                        <label class="control-label">Confirm Password<span class="required">*</span></label>
                    </div>
                    <div class="col-md-4">
                    <input class="form-control" type="password" placeholder="confirm password" [class.is-invalid]="validatePassForm && p.confirm_password.errors" (change)="validateConfirmPass()" formControlName="confirm_password"/>

                    <div class="invalid-feedback" *ngIf="p.confirm_password.errors">
                        <div *ngIf="p.confirm_password.errors.required">Please enter new password again here.</div>
                        <div *ngIf="p.confirm_password.errors.not_match">New password not matching.</div>
                    </div>

                    </div>
                </div>

                <div class="col-md-6">
                    <button class="btn btn-primary btn-sm mt-2" style="float: right;margin-right: 15px;" [disabled]="showOtp" (click)="savePassword()">change</button>
                </div>
            </form>
            <form [formGroup]="otpForm" *ngIf="showOtp">
                <div class="form-group row mt-2 mb-2">
                    <div class="col-md-2">
                        <label class="control-label">OTP<span class="required">*</span></label>
                    </div>
                    <div class="col-md-4">
                    <input class="form-control" type="text" formControlName="otp" placeholder="OTP" [class.is-invalid]="validateOtpForm && o.otp.errors"/>

                    <div class="invalid-feedback" *ngIf="o.otp.errors">
                        <div *ngIf="o.otp.errors.required">Please enter otp.</div>
                        <div *ngIf="o.otp.errors.incorrect">Invalid otp.</div>
                    </div>
                    <div class="mt-3 mb-3">
                        <p>Didn't get the code? <strong (click)="resendCode()"><a >Resend Code</a>
                          </strong>
                        </p>
                    </div>
                    <div>
                        <button class="btn btn-primary btn-sm" style="float: right;" (click)="sendPassword()"  >Confirm</button>
                    </div>
                    </div>
                
                </div>
            </form>
        </li>

        <li (click)="goToProfile()"><a>Change Interests <i class="fa fa-arrow-right"></i></a></li>

        <li [routerLink]="['/events/list-event']"><a>Check Events History <i class="fa fa-arrow-right"></i></a></li>

        <li (click)="goToProfile()"><a>Change/update Profile Information <i class="fa fa-arrow-right"></i></a></li>

        <li (click)="changeCollapse('feedback')"><a>Feedback to Presentsir.com <i class="fa fa-arrow-right"></i></a></li>

        <li class="card" *ngIf="collapse.showFeedback">
            <form class="card-body" [formGroup]="feedBackForm" style="background-color: #FFF7E9;">
                <div class="form-group row">

                    <div class="col-md-2">
                        <label class="control-label">Module <span class="required">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <select class="form-control" formControlName="module" [class.is-invalid]="validateFeedForm && f.module.errors">
                            <option>Please select</option>
                            <option *ngFor="let mod of modules" value="mod">{{mod}}</option>
                        </select>
                        <div class="invalid-feedback" *ngIf="f.module.errors">
                            <div *ngIf="f.module.errors.required">Please select module.</div>
                        </div>
                    </div>

                </div>

                <div class="form-group row mt-2">
                    <div class="col-md-2">
                        <label class="control-label">Feedback <span class="required">*</span></label>
                    </div>
                    <div class="col-md-4">
                        <textarea class="form-control" placeholder="Feedback" formControlName="feedback" [class.is-invalid]="validateFeedForm && f.feedback.errors"></textarea>

                        <div class="invalid-feedback" *ngIf="f.feedback.errors">
                            <div *ngIf="f.feedback.errors.required">Please enter your feedback.</div>
                            <div *ngIf="f.feedback.errors.minlength">Feedback is too short.</div>
                            <div *ngIf="f.feedback.errors.maxlength">Feedback is too long.</div>
                        </div>

                    </div>
                </div>

                <div class="col-md-6">
                    <button class="btn btn-primary btn-sm mt-2" style="float: right;margin-right: 15px;" (click)="sendFeedback()">submit</button>
                </div>
            </form>
        </li>
    </ul>    
</section>