import { Component, OnInit } from '@angular/core';
//import { SwPush, SwUpdate } from '@angular/service-worker'
import { ApiService } from '../services/api.service';
import {ENDPOINTS} from './../app.config';

@Component({
  selector: 'app-push-notification',
  templateUrl: './push-notification.component.html',
  styleUrls: ['./push-notification.component.scss']
})
export class PushNotificationComponent implements OnInit {

  readonly VAPID_PUBLIC_KEY = "BECXeIA8gjqveHeZP3r67maMUiWIL-662wTOR1AoU6uWuz3Hmq19ZLnWmnMttHo8TcB6m3o23Dz1Av9fyYzYn50";

  file:any = []
  image:any
  constructor(
    //private swPush: SwPush,
    //private swUpdate:SwUpdate,
    private apiService: ApiService,
  ) {
    //this.swUpdate.available.subscribe(event=>{
    //  console.log("e",event)
    //  this.swUpdate.activateUpdate().then(()=>document.location.reload())
    //})
    //this.subscribeNotification()
   }

   flag=false
  ngOnInit() {
    
  }

  subscribeNotification(){
    //this.swPush.notificationClicks.subscribe(({action,notification})=>{
    //  console.log("chk",notification)
    //  if(!this.flag){
    //    window.open(notification.data.url)
    //    this.flag=true
    //  }
    //})
    this.flag=false

    //this.swPush.requestSubscription({serverPublicKey:this.VAPID_PUBLIC_KEY})
    //.then(pushSubsription=>{
    //  console.log(pushSubsription.toJSON())
    //})
  }

  sendNotification(){
    console.log("Notification", this.file);
    let fr = new FileReader();
    fr.onload=(e:any)=>{
      console.log('txt',fr.result)
    }
    //this.ngOnInit();
    //this.subscribeNotification()
    //this.pushSubsription();
  }

  pushSubsription(){
    console.log("Push Notification");

    //this.swPush.requestSubscription(
    //  {
    //    serverPublicKey:this.VAPID_PUBLIC_KEY
    //  }
    //).then((res)=>{
    //  console.log(res);
    //  this.apiService.postApiCall(ENDPOINTS.EVENTNOTIFICATION, res).subscribe()
  //})
}

fileChanged(e:any){
  let reader = new FileReader();
  console.log(e.target.files)
  if(e.target.files.length>0){
    let file = e.target.files[0];
    console.log(file)
  }
  //reader.readAsDataURL(file)
  let value = <String>reader.result;

}

}

