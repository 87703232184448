import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ENDPOINTS } from 'src/app/app.config';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import {LocalStorageService} from '../services/local-storage.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DOMAIN_NAME,USER_ID } from 'src/app/app.config';
import {io} from 'socket.io-client';
import { ChatService } from '../chat/chat.service'

const emailPattern = /^[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+$/i

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  private url = 'http://localhost:4000/';
  private socket : any;   

  loginForm: FormGroup;
  validateForm : boolean = false;

  member_id : any;
  name:any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private localService: LocalStorageService,
    private apiService: ApiService,
    private notifyService : NotificationService,
    private chatService : ChatService
  ) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      email: this.formBuilder.control('', [Validators.required, Validators.maxLength(10), Validators.pattern('[0-9]{10}')]),
      password: this.formBuilder.control('', [Validators.required]),
    })
  }

  connectSocket(userId:any)
  {
    this.chatService.connectSocket(userId)
  }
  get f(){
    return this.loginForm.controls;
  }

  login(){
    if(this.loginForm.valid){
      this.validateForm = false;
      let requestBody = {	
        "domain_name": DOMAIN_NAME,
        "username": this.loginForm.value.email +'-'+DOMAIN_NAME ,
        "password": this.loginForm.value.password
    }

    this.apiService.postApiCall(ENDPOINTS.LOGIN, requestBody).subscribe(
      resp => {
        if (resp && resp.success == 1) {

          //this.connectSocket(resp.user.member_id)

          this.localService.set("accessToken", resp.access_token)
          this.localService.set("refreshToken", resp.refresh_token)
          this.localService.set("domainName", DOMAIN_NAME)
          this.localService.set("userId", USER_ID)
          this.localService.set("memberId", resp.user.member_id)
          this.localService.set("userName", resp.user.username)
          this.localService.set("phoneNumber", resp.user.phoneNumber)
          
          this.memberData()
        }
        else {
          this.notifyService.showError('Something went wrong', '');
          console.log("Invalid Error", resp);
        }
      },
      err => {
        if(err.status == 401)
        {
          this.notifyService.showError('Invalid Username/password', 'Invalid Credentials!');
        }
        else
        {
        this.notifyService.showError('Something went wrong', '');
        
        }
      }
    );
    }
    else{
      this.validateForm =true;
    }
  }
  forgot(){
    this.router.navigate(['/forgot-password'])
  }


  memberData() {
    this.member_id = this.localService.getMemberId()
    const requestBody = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "extras": {
        "find": {
          "uid": this.member_id
        }
      }
    }
    console.log(requestBody);
    this.apiService.postApiCall(ENDPOINTS.GET_MEMBER, requestBody).subscribe(
      resp => {
        let data = resp.result
        if(data.result.length){
          this.name = data.result[0].is_an[0].first_name
          this.localService.set("memberName", data.result[0].is_an[0].first_name)
          this.localService.set("memberStatus", data.result[0].status)
          this.localService.set("memberId", data.result[0].uid)
          this.apiService.PROFILE_PICTURE = data.result[0].is_an[0].picture != undefined ? data.result[0].is_an[0].picture : ""
          this.localService.set("picture", this.apiService.PROFILE_PICTURE)
          this.checkChatList()
          if(data.result[0].status == "Approved"){
            this.router.navigate(['/connection'])
          }
          else{
            this.router.navigate(['/member/myprofile/'+this.member_id])
          }
        }
        else{
          this.router.navigate(['/member/myprofile/'+this.member_id])
        }
        

      },
      err => {
        console.log("Error: ", err);
        // this.router.navigate([`/`]);
      }
    );
  }

  checkChatList(){
    const requestBody = {
      userId : this.loginForm.value.email
    }
    this.apiService.postApiCall(ENDPOINTS.GETUSERBYMOBILE, requestBody).subscribe(
      resp => {
        if(resp.error == false && resp.messages.length == 0){
          this.registerChat()
        }

      },
      err => {
        console.log("Error: ", err);
      }
    );
  }

  registerChat(){
    const requestBody = {
      username : this.loginForm.value.email,
      member_id: this.member_id,
      firstname: this.name
    }
    this.apiService.postApiCall(ENDPOINTS.CHATREGISTER, requestBody).subscribe(
      resp => {
      console.log(resp)
      this.router.navigate(['/connection'])

      },
      err => {
        console.log("Error: ", err);
      }
    );
  }
}
