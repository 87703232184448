import { Component, OnInit,Input } from '@angular/core';
import { ActivatedRoute,Router, NavigationStart, Event as NavigationEvent } from '@angular/router'
import { filter } from 'rxjs/operators';
import { ApiService } from '../../services/api.service';
import { LocalStorageService } from '../../services/local-storage.service';
import { ChatService } from '../../chat/chat.service'
// import { UserAccessService } from '../user-access.service';


@Component({
  selector: 'app-sidebar-filter',
  templateUrl: './sidebar-filter.component.html',
  styleUrls: ['./sidebar-filter.component.scss']
})
export class SidebarFilterComponent implements OnInit {
  activeMenu:string = 'connection';
  sidenav:boolean = false;
  url:any
  sidebar:boolean = true;
  profilePicture : any;
  showChat:boolean = false;

  @Input() showSidebar:any;
  @Input() registration:any;

  main_container_class = "main-container";
  currentScreen = '';
  productType='';
  domainName:any
  db_schema:any
  hide:boolean = false;
  obj:any
  obj1:any
  userName = '';
  mobile :any;
  url1: string;
  member_id: string;
  x = window.matchMedia("(max-width: 600px)")

  show : boolean = true
  viewProfile : boolean = false;

  constructor(private router: Router,private chatService : ChatService, private activatedRoute: ActivatedRoute,private localService: LocalStorageService, private apiService : ApiService) { 
    this.url1 = router.url.split('/')[3]
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationEvent) => {
      let events:any = event; 
      this.currentScreen = "";
      this.main_container_class = "main-container";
      this.url = events.url .split( '/' )[1];
      
     
      if(events.url === '/member/approved' || events.url === '/member/approve-reject' || events.url === '/member/approve-check'){
        this.activeMenu = 'approveReject'
      }
      else if(events.url === '/member/invite'){
        this.activeMenu = 'invite'
      }
      else if(events.url === '/connection'){
        this.activeMenu = 'connection'
      }
      else if(events.url === '/chat'){
        this.activeMenu = 'chat'
      }
      else if(events.url.split('/')[1] == 'events')
      {
        this.activeMenu = 'events'
      }
      else if(events.url.split('/')[1] == 'my-account')
      {
        this.activeMenu = 'my-account'
      }
      else
      {
        this.main_container_class = 'main-container';
        this.currentScreen = "";
        this.activeMenu = "";
      }
      
    })
  }

  ngOnInit(): void {
    this.member_id = this.localService.getMemberId()
    this.profilePicture = this.apiService.PROFILE_PICTURE
    console.log(this.profilePicture)
    this.userName = this.localService.get('memberName')
    this.domainName = this.localService.get('domainName')
    this.db_schema = this.localService.get('db_schema')
    this.mobile = this.localService.get("phoneNumber")
    // if(this.x.matches){
    //   this.sidebar=false;
    // }
    // else{
    //   this.sidebar=true;
    // }
  }
  toggle(){
    console.log('chk')
    if(document.getElementById('a').style.display == 'none'){
      document.getElementById('a').style.display = 'block';
      this.show = true
    }
    else{
      document.getElementById('a').style.display = 'none';
      this.show = false
    }
  }

  selectUser(){
    console.log('c')
    this.viewProfile=true;
  }
  close(){
    this.viewProfile=false;
  }
  ngAfterViewInit(): void {
 
  }
  
  snavToggle():void{
    !this.sidenav ? this.sidenav = true : this.sidenav = false;
  }

  loggout(){
    this.localService.remove('accessToken');
    this.localService.remove('refreshToken');
    this.localService.remove('expireTime');
    this.localService.remove('UserName');
    this.localService.remove('UserId');
    this.localService.remove('domainName');
    this.localService.remove('rexpireTime');
    this.router.navigate([`/login`], {skipLocationChange: false});
  }

  sideBar(){
    if(this.sidebar){
      this.sidebar = false;
    }
    else{
      this.sidebar = true
    }
  }

  logout(){
    let userId = this.localService.getMemberId()
    this.chatService.logout(userId)
    this.localService.remove('accessToken');
    this.localService.remove('refreshToken');
    this.localService.remove('userName');
    this.localService.remove('userId');
    this.localService.remove('domainName');
    this.localService.remove('memberId');
    this.localService.remove('memberName');
    this.localService.remove('memberStatus');
    this.localService.remove('Uid');
    this.localService.remove('phoneNumber');
    this.router.navigate([`/login`], {skipLocationChange: false});
  }
  route(){
    let URL = "/member/myprofile/" + this.member_id
    this.router.navigate([URL])
  }
  
}
