import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import {io} from 'socket.io-client';
import { Router, ActivatedRoute } from '@angular/router'
import { ChatService } from './chat.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ChatListResponse } from './../../app/interfaces/chat-list-response'
import { User } from './../../app/interfaces/user'
import {ApiService} from './../services/api.service';
import {ENDPOINTS, DOMAIN_NAME, USER_ID} from './../app.config';
import { NotificationService } from 'src/app/services/notification.service';


const SOCKET_ENDPOINT = "http://localhost:4000/"
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  @ViewChild('z') content: ElementRef;
  @ViewChild('scrollMe') private myScrollContainer : ElementRef
  dates:any = []
  datas:any=[]
message: any;
message_text: any;
messages : any
messageData : any = []
chatList : any = []
toUserId : any
toUserName : any
fromUserId : any
toPicture : any
fromPicture : any
messageLoading : boolean = false;
userId : any
public selectedUser: any
date : any
socket : any
chatListUsers: User[] = [];
userName = '';
  mobile :any;
  show : boolean = true
  viewProfile : boolean = false;
chkCurrentDate = ''
latestUsers :any = []
userData:any
searchId : any
searchData : any
fromUserName : any
isOnline : boolean = false;
newMsg : boolean = false;

@ViewChild('messageThread') private messageContainer: ElementRef;
       
constructor(private chatService: ChatService, private router : Router,private localService : LocalStorageService, private apiService : ApiService, private notifyService : NotificationService) 
{
  if (this.router.getCurrentNavigation() != undefined && this.router.getCurrentNavigation() != null) {
    if (this.router.getCurrentNavigation().extras.replaceUrl) {
      this.router.navigate(['/connection'])
  }
    if (this.router.getCurrentNavigation().extras.state) {
        this.toUserId = this.router.getCurrentNavigation().extras.state.toId;
        this.toUserName = this.router.getCurrentNavigation().extras.state.toName;
        this.toPicture = this.router.getCurrentNavigation().extras.state.picture;
    }

}
}
ngOnInit() {

  //this.chatService.connectSocket(this.localService.getMemberId())
  console.log(document.getElementById("mobileView"))
  this.toggle()
  document.getElementById('viewProfile').style.display="none"
  let currentDate = new Date();
  let cDay = currentDate.getDate();
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();
  this.chkCurrentDate = cDay + "/" +cMonth + "/" +cYear
  this.fromUserId = this.localService.getMemberId()
  this.fromUserName = this.localService.get('memberName')
  this.chatService.connectSocket(this.fromUserId)
  this.fromPicture = this.localService.get('picture')
  this.selectedUser  = {}
  this.selectedUser.username="ela"

  this.getMessagesByName();
  this.chatService.receiveMessages().subscribe((data) => this.updateMsg(data))
  this.chatService.getChatList(this.fromUserId).subscribe((data)=> this.getChatList(data))

  this.chatService.selectUserData.subscribe((id:any) => {
    this.getUserById(id)
  })
  
}
getUserById(id:any){
  this.latestUsers.forEach((element:any) => {
    if(element.mobile == id){
      this.selectUser(element)
      return
    }
  });

  if(id != ''){
    this.searchUserFromConnection(id)
  }
}
ngAfterViewChecked(){
  //this.scrollMessageContainer()
}

scrollToBottom = () => {
  try {
    this.content.nativeElement.scrollTop = this.content.nativeElement.scrollHeight;
  } catch (err) {}
}

getChatList(d:any){
  console.log(d)
}


renderChatList(chatListResponse: ChatListResponse): void {
  if (!chatListResponse.error) {
    if (chatListResponse.singleUser) {
      // if (this.chatListUsers.length > 0) {
      //   this.chatListUsers = this.chatListUsers.filter(function (obj: User) {
      //     return obj.member_id !== chatListResponse.chatList[0].member_id;
      //   });
      // }
      /* Adding new online user into chat list array */
      //this.chatListUsers = this.chatListUsers.concat(chatListResponse.chatList);
    } else if (chatListResponse.userDisconnected) {
      const loggedOutUser = this.chatListUsers.findIndex((obj: User) => obj.member_id === chatListResponse.userid);
      if (loggedOutUser >= 0) {
        this.chatListUsers[loggedOutUser].online = 'N';
      }
    } else {
      /* Updating entire chatlist if user logs in. */
      this.chatListUsers = chatListResponse.chatList;
    }
  } else {

  }
}

sendMessage(e:any) {
  let currentDate = new Date();
  let cDay = currentDate.getDate();
  let cMonth = currentDate.getMonth() + 1;
  let cYear = currentDate.getFullYear();
  let CurrentDate = cDay + "/" +cMonth + "/" +cYear

  let currentTime = new Date();
  let time = currentTime.getHours() + ":" + currentTime.getMinutes();

  this.message = {}
  this.message['fromUserId'] = this.fromUserId
  this.message['message'] = this.message_text
  this.message['toUserId'] = this.toUserId
  this.message['firstname'] = this.toUserName
  this.message['name'] = this.localService.get('memberName')
  this.message['messageTime'] = time
  this.message['messageDate'] = CurrentDate
  this.message['date'] = new Date()
  this.message['member_id'] = this.fromUserId
  this.message['newMsg'] = 'Y'
  console.log(this.message)
  
  this.chatService.sendMessage(this.message);

  this.messageData = [...this.messageData, this.message]
  this.findMsgByDate(this.messageData)
  this.getMessagesByName()
  this.message_text = ''
}

logout(){
  this.chatService.logout(this.fromUserId)
}

updateMsg(d:any){
   console.log(this.datas,d)
  if(d.fromUserId != this.fromUserId){
  let audio = new Audio();
  audio.src = 'assets/data/music/notification.mp3'
  audio.load()
  audio.play()
  }
  //this.toggle()
  d['new_msg'] = true
  this.newMsg = true
  d['member_id'] = this.toUserId
  this.messageData = [...this.messageData, d]
  this.findMsgByDate(this.messageData)
  this.getMessagesByName();
  if(d.fromUserId == this.toUserId || d.toUserId == this.toUserId){
    //this.selectUser(d)
  }
  
  console.log(d)

  //this.scrollMessageContainer()
  //this.getMessages(this.toUserId)
  this.scrollMessageContainer()
}


getMessages(toUserId:any) {
  this.messageLoading = true;
  this.messageData = []
  this.dates = []
  this.datas = []
  const requestBody = {
        userId: this.fromUserId, toUserId: toUserId
    }
    this.apiService.postApiCall(ENDPOINTS.GETMSG, requestBody).subscribe(
      resp => {

        if (resp.messages.length) {
          this.messageData = resp.messages
          console.log(this.messageData)
          
          //this.messages = resp.messages;
          this.findMsgByDate(resp.messages)

          this.messageLoading =  false;

          
        }
        else {
          //this.notifyService.showWarning('Message not found', '');
        }
      },
      err => {
        this.notifyService.showError('Something Went Wrong', '');
      }
    );
}

getMessagesByName() {
  this.messageLoading = true;
  const requestBody = {
        userId: this.fromUserId
    }
    this.apiService.postApiCall(ENDPOINTS.GETMSG1, requestBody).subscribe(
      resp => {
        if (resp.messages.length) {
          let m:any=[]
        
          let temp : any =[]
          this.latestUsers = []
          resp.messages.forEach((e:any)=>{
            if(e.fromUserId != this.fromUserId){
              if(!temp.includes(e.fromUserId)){
                temp.push(e.fromUserId)
                e['member_id'] = e.fromUserId
                //e['member_id'] = this.fromUserId
                this.latestUsers.push(e)
              }
            }
            else if(e.toUserId != this.fromUserId){
              if(!temp.includes(e.toUserId)){
                temp.push(e.toUserId)
                e['member_id'] = e.toUserId
                //e['member_id'] = this.fromUserId
                this.latestUsers.push(e)
              }
            }
            
          })
        this.newMsg = false
        if(this.latestUsers != undefined && this.latestUsers.length > 0){
        this.latestUsers.forEach((e:any)=>{
          if(e.newMsg == 'Y' && e.fromUserId != this.fromUserId){
            this.newMsg = true
            
          }
        })
      }
          
        }
        else {
          //this.notifyService.showWarning('Message not found', '');
        }
      },
      err => {
        this.notifyService.showError('Something Went Wrong', '');
      }
    );
}


  findMsgByDate(data:any){
    this.dates = []
    this.datas = []
    this.messages = {}
          data.forEach((element:any) => {
            if(element.messageDate){
              this.messages[element.messageDate] = []
            }
         });
          data.forEach((element:any) => {
            if(element.messageDate){
              this.messages[element.messageDate].push(element)

            }
          });
    Object.keys(this.messages).forEach((e:any)=>{
      this.dates.push(e)
    })
    Object.values(this.messages).forEach((e:any)=>{
      this.datas.push(e)
    })
  }

	scrollMessageContainer(): void {
    // this.myScrollContainer.nativeElement.scrollIntoView({behavior:"smooth",block:"start",inline:"nearest"});
    console.log(this.myScrollContainer)
		if (this.myScrollContainer !== undefined) {
			try {
				setTimeout(() => {
          this.myScrollContainer.nativeElement.scrollHeight;
					// this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
				}, 100);
			} catch (error) {
			}
		}
	}

	alignMessage(userId: string): boolean {
		return this.userId === userId ? false : true;
  }
  
  toggle(){
    if(document.getElementById('a').style.display == 'none'){
      document.getElementById('a').style.display = 'block';
      this.show = true
    }
    else{
      document.getElementById('a').style.display = 'none';
      this.show = false
    }
  }

  selectUser(data:any){
    console.log(data)
    this.viewProfile=true;
    this.getUser(data.member_id)
    if(data.newMsg != undefined){
      this.chatService.makeMessageRead(data)
    }
    document.getElementById('viewProfile').style.display="block"
    this.toUserName = data.firstname
    this.toUserName = (this.fromUserId != data.fromUserId && data.name != undefined) ? data.name : data.firstname
    this.toUserId = data.member_id
    this.getMessages(data.member_id)
    this.scrollMessageContainer()
    this.getMessagesByName()
  }

  updateUser(data:any){
    console.log(data)


  }

  getUser(id:any){
    const requestBody = {
          userId: id
      }
      this.apiService.postApiCall(ENDPOINTS.GETUSERBYID, requestBody).subscribe(
        resp => {
          if (resp.messages.length > 0) {
            if(resp.messages[0].online == 'Y')
            {
              this.isOnline = true
            }
            else{
              this.isOnline = false
            }
            
          }
          else {
            //this.notifyService.showWarning('Message not found', '');
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
        }
      );
  }

  searchUser(){
    console.log("chks")
    const requestBody = {
          userId: this.searchId
      }
      this.apiService.postApiCall(ENDPOINTS.GETUSERBYMOBILE, requestBody).subscribe(
        resp => {
          console.log(resp)
          if (resp.messages.length > 0) {
            this.searchId = ''
            this.latestUsers = []
            if(resp.messages[0].member_id !== this.fromUserId){
            this.latestUsers = resp.messages
            }
            else{
              this.notifyService.showWarning('User not found', '');
            }
          }
          else {
            this.notifyService.showWarning('User not found', '');
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
        }
      );
  }

  searchUserFromConnection(search:any){
    console.log("chkufc")
    const requestBody = {
          userId: search
      }
      this.apiService.postApiCall(ENDPOINTS.GETUSERBYMOBILE, requestBody).subscribe(
        resp => {
          console.log(resp)
          if (resp.messages.length > 0) {

            if(resp.messages[0].member_id !== this.fromUserId){
            this.selectUser(resp.messages[0])
            }
            else{
              this.notifyService.showWarning('User not found', '');
            }
          }
          else {
            this.notifyService.showWarning('User not found', '');
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
        }
      );
  }


  getOriginal(){

    if(this.searchId == ''){
      this.chatListUsers=[]
      this.getMessagesByName()
      //this.chatService.getChatList(this.fromUserId)
    }
  }

  close(){
    this.viewProfile=false;
    document.getElementById('viewProfile').style.display="none"
  }
}

