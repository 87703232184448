<div *ngIf="userSelected" class="chat-box2" style="width:107% !important; margin-left:-4px; margin-top:66px; border-radius: 1px; background-color: #1746A2;">
    <div class="chat-head2">
      
          <div class="row" > 
              <div style="width: 20%;">
                  <img class="picture" src="assets/images/profile.png" alt="avatar 1"
                  style="width:45px;border-radius: 50%;padding-top: 3px;padding-left: 4px;">
              </div>
              <div style="width: 60%;">
                  <h6>{{toUserName}}</h6>
                  
                  <p class="form-text text-muted text-muted-color" style="color: #ffffff !important;">{{isOnline ? 'Online' : 'Offline'}}</p>
              <!-- ///<span [ngClass]="user.online === 'Y' ? 'online' : 'offline'"> </span> -->
              
              </div>
              <div style="width: 20%;float: right;">

                  <span title="Expand Arrow" width="16" style="float: right;" (click)="close()"><i class="fa fa-close" style="color: white;width: 30px;margin-top: 5px;"></i></span>
              </div>

          </div>
      
      <!-- <img class="avatar" src="assets/images/profile.png" alt="avatar 1"
      style="width: 45px; height: 100%;">
      <h5>{{toUserName}}</h5>
        <span title="Expand Arrow" width="16" style="float: right;" (click)="close()"><i class="fa fa-close" style="color: white;width: 30px;margin-top: 5px;"></i></span> -->
    </div>
    <div class="card" style="width: 100%; background-color: #FFE7D0;">
    <div class="chat-body2" style="height: 70vh;overflow-y: auto;background-color: #FFE7D0;">

      <section>
          <!-- <div class="container py-5"> -->
            <!-- <div class="row d-flex justify-content-center">
              <div class="col-md-12 col-lg-12 col-xl-12"> -->
                <!-- <div class="card" style="width: 100%;"> -->
              
                  <div class="card-body2"  #scrollMe [scrollTop]="scrollMe.scrollHeight" style="overflow-y: auto;">
                    
                    <div>
                    <div  *ngFor = "let date of dates;let i = index;">
                      <p id="hl" class="form-text text-muted text-muted-color" >{{date == chkCurrentDate ? 'Today' : date }}</p>
                  <div *ngFor ="let data of datas[i]" style="overflow-y: auto;">	
                    <div *ngIf="fromUserId != data.fromUserId" class="d-flex flex-row justify-content-start" style="padding-left: 10px;">
                      <img class="picture" src="assets/images/profile.png" alt="avatar 1"
                      style="width:45px;height:45px;border-radius: 50%;padding-top: 3px;padding-left: 4px;">
                      <div>
                        <p class="small p-2 ms-3 mb-3 rounded-3"  style="background-color: #5F9DF7; border-radius: 10px; color:white" >{{data.message}}<br><span class="form-text" style="color:#eee">{{data.messageTime}}</span> </p>
                      </div>
                  
                    </div>
                  
                    <div *ngIf="fromUserId == data.fromUserId" class="d-flex flex-row justify-content-end mb-4 pt-1" style="padding-right:10px">
                      <div>
                        <p class="small p-2 me-3 mb-3 rounded-3"  style="background-color: #1746A2; border-radius: 10px; color:white" > {{data.message}}<br><span class="form-text" style="color:#eee">{{data.messageTime}}</span> </p>
                      
                      </div>
                      <img class="picture" [src]="fromPicture != '' ? fromPicture : 'assets/images/profile.png'" alt="avatar 1"
                      style="width:45px;height:45px;border-radius: 50%;padding-top: 3px;padding-left: 4px;">
                    </div>
                    </div>
                    </div>
                    </div>
                  </div>
                    
                
                <!-- </div>  -->
              <!-- </div>
            </div> -->
          <!-- </div> -->
        </section>
        <br>
    </div>
    <div class="card-footer text-muted d-flex justify-content-start align-items-center p-3">
        <div class="input-group mb-0">
          <input type="text" class="form-control" (keyup.enter)="sendMessage($event)" placeholder="Type message" [(ngModel)]="message_text" />
            <button  (click)="sendMessage($event)" class="btn btn-warning" type="submit" id="button-addon2" style="padding-top: .55rem;">
            Send
          </button>
        </div>
      </div>
    </div>
    
  </div>


<div *ngIf="!userSelected" class="card" style="height: 100%; border: none;">
    <div style="overflow-y: scroll;background-color: #FFF7E9;">
      <div class="input-group md-form form-sm form-2 pl-0">
          <input class="form-control my-0 py-1 red-border" type="text" [(ngModel)]="searchId" placeholder="Search" aria-label="Search" (input)="getOriginal()">
          <div class="input-group-append" >
            <span class="input-group-text red lighten-3" (click)="searchUser()" style="cursor: pointer; background-color: #1746A2; color:white; height:2.2rem" id="basic-text1"><i style="height:15px;width:15px;" class="fa fa-pencil-square-o"
                ></i></span>
          </div>
        </div>
        <hr style="color:#FFF7E9 ;margin:0.4rem;">
      <ul class="user-list" style="overflow: hidden;">
          <li class="row " style="box-shadow: 10px 5px 5px #FFE7D0;" *ngFor="let user of latestUsers" 
          (click)="selectUser(user)" >
              <div style="width: 15%;">
                  <img class="picture" src="assets/images/profile.png" alt="avatar 1"
          style="width:50px;border-radius: 50%;">
              </div>

              <div style="width: 85%;float: right;">
                  <label *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId == fromUserId" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
                  <label *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId != fromUserId" style="margin-bottom: 5px;"><b>&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</b></label>
                  <label *ngIf="user.newMsg != undefined && user.newMsg=='N'" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
                  <label *ngIf="user.newMsg == undefined" style="margin-bottom: 5px;">&nbsp;{{(fromUserId != user.fromUserId && user.name != undefined) ? user.name : user.firstname}}</label>
              <p *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId == fromUserId" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
              <p *ngIf="user.newMsg != undefined && user.newMsg=='Y' && user.fromUserId != fromUserId" style="white-space: nowrap;overflow: hidden;"><b>&nbsp;{{user.message}}</b></p>
              <p *ngIf="user.newMsg != undefined && user.newMsg=='N'" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
              <p *ngIf="user.newMsg == undefined" class="form-text text-muted text-muted-color" style="white-space: nowrap;overflow: hidden;">&nbsp;{{user.message}}</p>
              </div>
          </li>
      </ul>
    </div>
</div>