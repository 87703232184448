import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import {AppPaginationComponent} from '../services/app-pagination/app-pagination.component';
import { ToastrModule } from 'ngx-toastr';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { ChatComponent } from '../chat/chat.component'
@NgModule({
  declarations: [
    AppPaginationComponent,
    // ChatComponent
      ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      //enabled:true
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      //registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  exports: [
  CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    AppPaginationComponent,
    ToastrModule,
    // ChatComponent
  ]
})
export class SharedModule { }
