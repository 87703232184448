import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  settemp: any;

  set(key: string, value: any) {
    localStorage.setItem(key, value);
  }

  get(key: string) {
    return localStorage.getItem(key);
  }

  remove(key: string) {
    localStorage.removeItem(key);
  }

  clear(){
    localStorage.clear();
  }

  getUserId()
  {
    return localStorage.getItem('userId') != undefined ? Number(localStorage.getItem('userId')) : null;
  }

  getUserName()
  {
    return (localStorage.getItem('userName') != undefined ? localStorage.getItem('userName') : null);
  }

  getMemberId()
  {
    return localStorage.getItem('memberId') != undefined ? localStorage.getItem('memberId') : null;
  }

  getDomain()
  {
    return localStorage.getItem('domainName') != undefined ? localStorage.getItem('domainName') : null;
  }
   gettemp(key: string) {
     return sessionStorage.getItem(key);
   }
   settempn(key: string, value: any) {
    sessionStorage.setItem(key, value);
   }
   
   cleartemp(){
     sessionStorage.clear();
   }

}
