import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute,Router } from '@angular/router';
import {ApiService} from './../services/api.service';
import {LocalStorageService} from './../services/local-storage.service';
import {ENDPOINTS, DOMAIN_NAME, USER_ID} from './../app.config';
import { NotificationService } from 'src/app/services/notification.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { ChatComponent } from '../chat/chat.component'
import { ChatService } from '../chat/chat.service'

@Component({
  selector: 'app-connection',
  templateUrl: './connection.component.html',
  styleUrls: ['./connection.component.scss']
})
export class ConnectionComponent implements OnInit {
  @ViewChild (ChatComponent) chatComponent : ChatComponent

  searchForm: FormGroup;
  form: FormGroup;
  allCategories: any;
  preList:any = [];
  searchTerm: any;
  superAdmin : boolean = false
  validateForm :boolean = false;
  member_id : any;
  secondary_id : any;
  secondary_id1 : any;
  uid_list : any = []
  invitee_known:any
  name:any;
  invitee_known1:any
  name1:any;
  cardData : any = []
  isSearch : boolean = false;
  profile : boolean = false;
  profileData:any = []
  comments:any
  user_member_id :any

  areaInterest : any = []

  school:boolean = false
  college:boolean = false
  professional:boolean = false
  certificate:boolean =false

  profile_member_id : any
  profile_member_name :any
  profile_picture : any
  profile_mobile : any

  pageData : any = []
  pageCount :any;
  currentPage :any =1;
  d : any

  constructor(
    private router: ActivatedRoute,
    private routeUrl : Router,
    private apiService : ApiService,
    private localService : LocalStorageService,
    private formBuilder: FormBuilder,
    private notifyService : NotificationService,
    private chatService : ChatService
  ) { }

  ngOnInit(): void {
    this.member_id = this.localService.getMemberId()
    this.searchForm = new FormGroup({
      search_term: this.formBuilder.control(''),
      category_type:this.formBuilder.control('',[Validators.required]),
    })
    this.form = new FormGroup({
      comments: this.formBuilder.control('', []),
    });
    this.getAllCategories();
    //this.getUid()
    this.getConnection()
  }

  ngAfterViewInit():void{

  }

  get g(){return this.searchForm.controls}

  getConnection(){
    let requestBody = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "extras": {
        "find": {
          "member_id": this.member_id
        }
      }
    }
    this.apiService.postApiCall(ENDPOINTS.CONNECTION,requestBody).subscribe((resp)=>
    {
      console.log(resp)
      if(resp)
      {
        let rowData = resp.result
        rowData.forEach((rowData1:any) => {
        
          if(rowData1.school != undefined && rowData1.school.length>0){
            rowData1.school.forEach((e:any)=>{
              this.setData(e)
            })
          }
          if(rowData1.college != undefined && rowData1.college.length>0){
            rowData1.college.forEach((e:any)=>{
              this.setData(e)
            })
          }
          if(rowData1.certification != undefined && rowData1.certification.length>0){
            rowData1.certification.forEach((e:any)=>{
              this.setData(e)
            })
          }
          if(rowData1.profession != undefined && rowData1.profession.length>0){
            rowData1.profession.forEach((e:any)=>{
              this.setData(e)
            })
          }
        });
      }
    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }

  getAllCategories()
  {
    let requestBody = {	
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "payload":{	
      },
      "extras":{	
        "find":{}	
        }	
      }	
    this.apiService.postApiCall(ENDPOINTS.EVENT_CATEGORY_GETALL,requestBody).subscribe((resp)=>
    {
      console.log(resp)
      if(resp && resp.success ==1)
      {
        this.allCategories = resp.result.data;
      }
    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }

  pageChange(evt:any)
  {
      this.pageData = []

      let data = JSON.parse(JSON.stringify(this.cardData))
      this.pageData = data.splice(((Number(evt)-1)*10),10)
      console.log(evt,this.pageData,this.cardData)
  }

  getFilterCategory()
  {
    let searchData = this.searchForm.value.search_term;
    let categoryData = this.searchForm.value.category_type;
    const Uid = this.localService.get("Uid");
    if(!searchData){
      this.notifyService.showError('Please Enter Search Data', '');
    } else if (!categoryData){
      this.notifyService.showError('Please Select Category', '');
    }
    if(searchData && categoryData){
      let requestBody = {	
        "domain_name": DOMAIN_NAME,
        "user_id": USER_ID,
        "payload":{	
        },
        "extras":{	
          "find":{
            "member_id":this.member_id,
            "category":categoryData,
            "name":searchData
          }	
          }	
        }	
      this.apiService.postApiCall(ENDPOINTS.FILTER_CATEGORY,requestBody).subscribe((resp)=>
      {
        console.log(resp)
        if(resp)
        {
          
                this.cardData = []  
                this.uid_list = []
                this.pageData = []
            let rowData1 :any

            if(resp.result != undefined){
            if(resp.result.result != undefined){
              rowData1 = resp.result.result
              this.superAdmin = true
            }
            else{
              rowData1 = resp.result
              this.superAdmin = false
            }
            if(this.superAdmin){
              console.log("test",rowData1)
              rowData1.forEach((el:any) => {
                if(el.node_name =='School'){
                  this.isSearch = true
                    this.setData(el)
                }
                if(el.node_name =='College'){
                  this.isSearch = true
                    this.setData(el)
                }
                if(el.node_name =='Certification'){
                  this.isSearch = true
                    this.setData(el)
                }
                if(el.node_name =='Profession'){
                  this.isSearch = true
                    this.setData(el)
                }
              });
              
            }
            else{
              console.log("test",rowData1.school)
              if(rowData1.school != undefined && rowData1.school.length>0){
                rowData1.school.forEach((e:any)=>{
                  this.isSearch = true
                  this.setData(e)
                })
              }
              if(rowData1.college != undefined && rowData1.college.length>0){
                rowData1.college.forEach((e:any)=>{
                  this.isSearch = true
                  this.setData(e)
                })
              }
              if(rowData1.certification != undefined && rowData1.certification.length>0){
                rowData1.certification.forEach((e:any)=>{
                  this.isSearch = true
                  this.setData(e)
                })
              }
              if(rowData1.profession != undefined && rowData1.profession.length>0){
                rowData1.profession.forEach((e:any)=>{
                  this.isSearch = true
                  this.setData(e)
                })
              }
            }
          }
          else{
            this.isSearch = false
              this.notifyService.showWarning('No Matching result found', '')
              this.cardData=[]
              this.uid_list = []
              this.pageData = []
          }
              
          }
          else{
            if(!this.isSearch){
              this.isSearch = false
              this.notifyService.showWarning('No Matching result found', '')
              this.cardData=[]
              this.uid_list = []
              this.pageData = []
            }
          }
        //   if(resp.result.result.length){
        //   this.isSearch = true
        //   this.cardData = []  
        //   this.uid_list = []
        //   this.pageData = []
        //   resp.result.result.forEach((e:any)=>{
        //     this.setData(e)
        //   })
        // }
        // else{
        //   this.isSearch = false
        //   this.notifyService.showError('No Matching result found', '')
        //   this.cardData=[]
        //   this.uid_list = []
        //   this.pageData = []
        //   //this.getUid()
        // }
        
      },err=>
      {
        if (err && err.error && err.error.statusCode === 403) {
          this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
          this.routeUrl.navigate([`/login`], { skipLocationChange: false });
        }
      })
    }
  }

  getOriginal(){
    let searchData = this.searchForm.value.search_term;
    if(searchData == ''){
      this.isSearch = false
      this.cardData=[]
      this.uid_list = []
      this.getConnection()
    }
  }

  closePrelist(){
    this.searchForm.controls['search_term'].setValue('');
    this.preList = [];
  }

  getUid()
  {
    let requestBody = {	
      "domain_name": this.localService.getDomain(),
      "user_id": this.localService.getUserId(),
      "payload":{	
      },
      "extras":{	
        "find":{
        "uid":this.member_id
        }	
      }
      }	
    this.apiService.postApiCall(ENDPOINTS.GET_BY_UIDS,requestBody).subscribe((resp)=>
    {
      console.log(resp)
      if(resp && resp.success ==1)
      {
        this.secondary_id = resp.result.result[0].secondary_id
        console.log(this.secondary_id)
      }
      if(this.secondary_id){
        this.getInvitee()
      }
    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }

  getInvitee()
  {
    let requestBody = {	
      "domain_name": this.localService.getDomain(),
      "user_id": this.localService.getUserId(),
      "payload":{	
      },
      "extras":{	
        "find":{
          "uid":this.secondary_id
        }	
        }	
      }	
    this.apiService.postApiCall(ENDPOINTS.GET_INVITEE_BY_UID,requestBody).subscribe((resp)=>
    {
      console.log(resp)
      if(resp && resp.success ==1)
      {
        this.invitee_known = resp.result.result[0].invitee_known_during;
        //this.name = resp.result.result[0].name
        this.getIndividualConection()
        this.getCategoryConnection(resp.result.result[0].invitee_Known_during,resp.result.result[0].name)
        //this.getUidsData()
      }
    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }

  getIndividualConection()
  {
    let requestBody = {	
      "domain_name": this.localService.getDomain(),
      "user_id": this.localService.getUserId(),
      "payload":{	
      },
      "extras":{	
        "find":{
          "member_id": this.member_id,
        }	
        }	
      }	
    this.apiService.postApiCall(ENDPOINTS.GET_INDIVIDUAL_CONNECTIONS,requestBody).subscribe((resp)=>
    {
      console.log("c",resp)
      let data:any
      let sec_ids: any  = []
      if(resp && resp.success ==1)
      {
        data=resp.result.result[0]
        if(resp.result.result.length){
          if(data.has_invited != undefined){
          data.has_invited.forEach((e:any) => {
            sec_ids.push(e.uid)
          });
        }
          this.uid_list.push(data.referrer_id)
          if(sec_ids.length){
            this.getMemberId(sec_ids)
          }
          else{
            this.getUidsData()
          }

        }

      }
    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }

  getMemberId(sec_ids:any){
      let requestBody = {	
        "domain_name": this.localService.getDomain(),
        "user_id": this.localService.getUserId(),
          "extras": {
              "find": {
                  "secondary_id": sec_ids
              }
          }
      }
      this.apiService.postApiCall(ENDPOINTS.GET_MEMBERID,requestBody).subscribe((resp)=>
      {
       
        if(resp && resp.success ==1)
        {
          resp.result.data.forEach((e:any)=>{
            if(e.status == 'Approved'){
              this.uid_list.push(e.member_id)
              
            }
          })
          console.log(this.uid_list)
          this.getUidsData()
        }
      },err=>
      {
        if (err && err.error && err.error.statusCode === 403) {
          this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
          this.routeUrl.navigate([`/login`], { skipLocationChange: false });
        }
      })
  }

  getCategoryConnection(invitee_known:any,name:any)
  {
    let requestBody = {	
      "domain_name": this.localService.getDomain(),
      "user_id": this.localService.getUserId(),
      "payload":{	
      },
      "extras":{	
        "find":{
          "member_id": this.member_id,
          "invitee_known_during" : invitee_known,
          "name": name
        }	
        }	
      }	
    this.apiService.postApiCall(ENDPOINTS.GET_CATEGORY_CONNECTIONS,requestBody).subscribe((resp)=>
    {
      
      if(resp && resp.success ==1)
      {
        resp.result.result.forEach((element: any) => {
          element[Object.keys(element)[1]].forEach((ele2: any) => {
              if (ele2['~is_an'] != undefined && ele2['~is_an'][0] != undefined) {
                  this.uid_list.push(ele2['~is_an'][0]['uid'])
              }
          });
      });

      


      }
    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }

  getUidsData()
  {
    console.log(this.uid_list)
    let temp : any =[]
    this.uid_list = this.uid_list.filter((e:any)=>{
      if(!temp.includes(e)){
        temp.push(e)
        return true
      }
      else{
        return false
      }
    })
    console.log(this.uid_list)
    let requestBody = {	
      "domain_name": this.localService.getDomain(),
      "user_id": this.localService.getUserId(),
      "payload":{	
      },
      "extras":{	
        "find":{
          "uids":this.uid_list
        }	
        }	
      }	
    this.apiService.postApiCall(ENDPOINTS.GET_UIDS_DATA,requestBody).subscribe((resp)=>
    {
      console.log("chk",resp)
      if(resp && resp.success ==1)
      {
        
        resp.result.forEach((e:any) => {
          if(e.result.length){
            let data:any ={}

            data['email'] = e.result[0].email
            data['dob'] = e.result[0].is_an[0].dob
            data['first_name'] = e.result[0].is_an[0].first_name 
            data['member_id'] = e.result[0].is_an[0].member_id
            if(!this.isSearch){
              data['picture'] = e.result[0].is_an[0].picture != undefined ? e.result[0].is_an[0].picture :''
            }
            if(e.result[0].is_an[0].completed_primary_schooling_in != undefined){
              data['school_name'] = e.result[0].is_an[0].completed_primary_schooling_in[0].school_name
            }
            if(e.result[0].is_an[0].completed_middle_schooling_in != undefined){
              data['school_name'] = e.result[0].is_an[0].completed_middle_schooling_in[0].school_name
            }
            if(e.result[0].is_an[0].completed_high_schooling_in != undefined){
              data['school_name'] = e.result[0].is_an[0].completed_high_schooling_in[0].school_name
            }
            if(e.result[0].is_an[0].completed_associate_in != undefined){
              data['college_name'] = e.result[0].is_an[0].completed_associate_in[0].college_name
            }
            if(e.result[0].is_an[0].completed_diploma_in != undefined){
              data['college_name'] = e.result[0].is_an[0].completed_diploma_in[0].college_name
            }
            if(e.result[0].is_an[0].completed_bachelor_degree_in != undefined){
              data['college_name'] = e.result[0].is_an[0].completed_bachelor_degree_in[0].college_name
            }
            if(e.result[0].is_an[0].completed_master_degree_in != undefined){
              data['college_name'] = e.result[0].is_an[0].completed_master_degree_in[0].college_name
            }
            if(e.result[0].is_an[0].completed_doctorate_in != undefined){
              data['college_name'] = e.result[0].is_an[0].completed_doctorate_in[0].college_name
            }
            if(e.result[0].is_an[0].completed_certification_in != undefined){
              data['college_name'] = e.result[0].is_an[0].completed_certification_in[0].college_name
            }
            if(e.result[0].is_an[0].profession_info != undefined){
              data['college_name'] = e.result[0].is_an[0].profession_info[0].college_name
            }
            this.cardData.push(data)

            this.pageCount = this.cardData.length;
            this.pageData = []

            let rowData = JSON.parse(JSON.stringify(this.cardData))
            this.pageData = rowData.splice(0,10)
            // this.setData(e.result[0])
          }
          
        });
        console.log(this.cardData)
      }
    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }

  setData(data:any){
    var key : any =""
    let Data : any = {}
    console.log(data)
    if(data['~completed_school_in'] != undefined){
      this.name = data.school_name
      Data['school'] = []
      Data['school'].push({"school_name":data.school_name,"address":data.address,"class":data.class,"section":data.section,"start_year":data.start_year,"end_year":data.end_year})
      // Data['school_level']=data.school_level
      // Data['address']=data.address
      // Data['board']=data.board
      // Data['class']=data.class
      // Data['section']=data.section
      // Data['start_year']=data.start_year
      // Data['end_year']=data.end_year
      // Data['member_id']=data.member_id

      key="~completed_school_in"
      Data['first_name'] = data[key][0].first_name
      Data['middle_name'] = data[key][0].middle_name
      Data['last_name'] = data[key][0].last_name
      Data['dob'] = data[key][0].dob
      Data['member_id']=data[key][0].member_id
      Data['mobile'] = data[key][0]['~is_an'][0].mobile
      Data['email'] = data[key][0]['~is_an'][0].email
      Data['referrer_id'] = data[key][0]['~is_an'][0].referrer_id
      Data['secondary_id'] = data[key][0]['~is_an'][0].secondary_id
      Data['status'] = data[key][0]['~is_an'][0].status
      
        Data['picture'] = data[key][0]['~is_an'][0].picture != undefined ? data[key][0]['~is_an'][0].picture :''
      
    }
    else if(data['~completed_college_in'] != undefined){
      this.name = data.college_name
      // Data['degree_type']=data.degree_type
      Data['college']=[]
      Data['college'].push({"college_name":data.college_name,"address":data.address,"degree_type":data.degree_type,"stream":data.stream,"course":data.course,"start_year":data.start_year,"end_year":data.end_year})
      // Data['affiliated_to']=data.affiliated_to
      // Data['address']=data.address
      // Data['stream']=data.stream
      // Data['course']=data.course
      // Data['start_year']=data.start_year
      // Data['end_year']=data.end_year
      // Data['member_id']=data.member_id

      key = "~completed_college_in"  
      Data['first_name'] = data[key][0].first_name
      Data['middle_name'] = data[key][0].middle_name
      Data['last_name'] = data[key][0].last_name
      Data['dob'] = data[key][0].dob
      Data['member_id']=data[key][0].member_id
      Data['mobile'] = data[key][0]['~is_an'][0].mobile
      Data['email'] = data[key][0]['~is_an'][0].email
      Data['referrer_id'] = data[key][0]['~is_an'][0].referrer_id
      Data['secondary_id'] = data[key][0]['~is_an'][0].secondary_id
      Data['status'] = data[key][0]['~is_an'][0].status
    
        Data['picture'] = data[key][0]['~is_an'][0].picture != undefined ? data[key][0]['~is_an'][0].picture :''
      
    }
    else if(data['~completed_certification_in'] != undefined){

      // Data['certification_course']=data.certification_course
      Data['certification'] = []
      Data['certification'].push({"institution_name":data.institution_name,"certification_course":data.certification_course,"address":data.address,"start_year":data.start_year,"end_year":data.end_year})
      // Data['affiliated_to']=data.affiliated_to
      // Data['address']=data.address
      // Data['start_year']=data.start_year
      // Data['end_year']=data.end_year
      // Data['member_id']=data.member_id
      this.name = data.institution_name
      key = "~completed_certification_in"
      Data['first_name'] = data[key][0].first_name
      Data['middle_name'] = data[key][0].middle_name
      Data['last_name'] = data[key][0].last_name
      Data['dob'] = data[key][0].dob
      Data['member_id']=data[key][0].member_id
      Data['mobile'] = data[key][0]['~is_an'][0].mobile
      Data['email'] = data[key][0]['~is_an'][0].email
      Data['referrer_id'] = data[key][0]['~is_an'][0].referrer_id
      Data['secondary_id'] = data[key][0]['~is_an'][0].secondary_id
      Data['status'] = data[key][0]['~is_an'][0].status
      
        Data['picture'] = data[key][0]['~is_an'][0].picture != undefined ? data[key][0]['~is_an'][0].picture :''
      
    }
    else if(data['~profession_info'] != undefined){

      // Data['style']=data.style
      Data['profession'] = []
      Data['profession'].push({"firm_name":data.firm_name,"address":data.address,"style":data.style,"start_year":data.start_year,"end_year":data.end_year})
      // Data['designation']=data.designation
      // Data['address']=data.address
      // Data['start_year']=data.start_year
      // Data['end_year']=data.end_year
      // Data['member_id']=data.member_id
      this.name = data.firm_name
      key = "~profession_info"
      Data['first_name'] = data[key][0].first_name
      Data['middle_name'] = data[key][0].middle_name
      Data['last_name'] = data[key][0].last_name
      Data['dob'] = data[key][0].dob
      Data['member_id']=data[key][0].member_id
      Data['mobile'] = data[key][0]['~is_an'][0].mobile
      Data['email'] = data[key][0]['~is_an'][0].email
      Data['referrer_id'] = data[key][0]['~is_an'][0].referrer_id
      Data['secondary_id'] = data[key][0]['~is_an'][0].secondary_id
      Data['status'] = data[key][0]['~is_an'][0].status
        Data['picture'] = data[key][0]['~is_an'][0].picture != undefined ? data[key][0]['~is_an'][0].picture :''
      
    }
    this.cardData.push(Data)
    this.pageCount = this.cardData.length;
    this.pageData = []

    let rowdata = JSON.parse(JSON.stringify(this.cardData))
    this.pageData = rowdata.splice(0,10)
    console.log("se",this.cardData,this.pageData)
  }

  back(){
    this.profile = false;
  }

  viewPending(row:any){
    const requestBody = {
          "domain_name": this.localService.getDomain(),
          "user_id": this.localService.getUserId(),
          "extras": {
              "find": {
                  "secondary_id": row.uid
              }
          }
      }
      this.apiService.postApiCall(ENDPOINTS.GET_MEMBERID, requestBody).subscribe(
        resp => {
          if (resp) {
            console.log(resp)
            if(resp.result.data.length){
              this.user_member_id = resp.result.data[0].member_id
              resp.result.data.forEach((e:any)=>{
                if(e.status != "Submitted")
                {
                  this.comments = e.comments
                }
              })
              this.getMemberData()
            }
          }
          else {
            this.notifyService.showError('Something Went Wrong', 'Member Registration Approval');
            console.log("Invalid Error", resp);
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', 'Member Registration Approval');
          console.log("Error", err);
        }
      );
  }

  getProfileData(row:any){
    console.log(row)
    this.profile = true;
    this.profileData = row
    this.d = row
    this.profile_member_id = row.member_id
    this.profile_member_name = row.first_name
    this.profile_picture = row.picture
    this.profile_mobile = row.mobile
    //this.getUid1()
  }

  getUid1()
  {
    let requestBody = {	
      "domain_name": this.localService.getDomain(),
      "user_id": this.localService.getUserId(),
      "payload":{	
      },
      "extras":{	
        "find":{
        "uid": this.profile_member_id
        }	
      }
      }	
    this.apiService.postApiCall(ENDPOINTS.GET_BY_UIDS,requestBody).subscribe((resp)=>
    {
      console.log(resp)
      if(resp && resp.success ==1)
      {
        this.secondary_id1 = resp.result.result[0].secondary_id
        this.profileData['picture'] = resp.result.result[0].is_an[0].picture != undefined ? resp.result.result[0].is_an[0].picture : ''
        console.log(this.secondary_id1)
      }
      if(this.secondary_id1){
        this.getInvitee1()
      }

    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }

  getInvitee1()
  {
    let requestBody = {	
      "domain_name": this.localService.getDomain(),
      "user_id": this.localService.getUserId(),
      "payload":{	
      },
      "extras":{	
        "find":{
          "uid":this.secondary_id1
        }	
        }	
      }	
    this.apiService.postApiCall(ENDPOINTS.GET_INVITEE_BY_UID,requestBody).subscribe((resp)=>
    {
      console.log(resp)
      if(resp && resp.success ==1)
      {
        if(resp.result.result.length){
          this.invitee_known1 = resp.result.result[0].invitee_Known_during;
          this.name1 = resp.result.result[0].name
          this.getMemberData()
        }
        // if(this.invitee_known1 != undefined && this.name1 != undefined){
        //   this.getMemberData()
        // }
        else{
          this.notifyService.showError('No data found', '')
        }
      }
    },err=>
    {
      if (err && err.error && err.error.statusCode === 403) {
        this.notifyService.showError('Session Time Out! Please login Again', 'Session time out')
        this.routeUrl.navigate([`/login`], { skipLocationChange: false });
      }
    })
  }


  getMemberData(){
    let requestBody = {
          "domain_name": this.localService.getDomain(),
          "user_id": this.localService.getUserId(),
          "extras": {
              "find": {
                  "member_id": this.profile_member_id,
                  "invitee_known_during": this.invitee_known1,
                  "name": this.name
              }
          }
      }
      this.apiService.postApiCall(ENDPOINTS.GET_MEMBERDATA, requestBody).subscribe(
        resp => {
          if (resp) {
            console.log("member",resp)
            
            this.setData1(resp.result.result[0])
            this.getAreaOfInterest()
            this.getAcademics()
            this.getAcademicsCollege()
            this.getProfession()
            this.getCertificate()
          }
          else {
            this.notifyService.showError('Record not found', '');
            console.log("Invalid Error", resp);
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
          console.log("Error", err);
        }
      );
  }

  setData1(data:any){
    this.profile = true;
    var key : any =""
    if(data.node_name == 'School'){
      this.school = true
      this.college = false
      this.professional = false
      this.certificate = false

      this.profileData['school_name']=data.school_name
      this.profileData['school_level']=data.school_level
      this.profileData['address']=data.address
      this.profileData['board']=data.board
      this.profileData['class']=data.class
      this.profileData['section']=data.section
      this.profileData['start_year']=data.start_year
      this.profileData['end_year']=data.end_year
      this.profileData['member_id']=data.member_id

      if(data.school_level == 'Primary'){
        key= "~completed_primary_schooling_in"
      }else if(data.school_level == 'Middle'){
        key = "~completed_middle_schooling_in"
      }else if(data.school_level == 'High'){
        key = "~completed_high_schooling_in"
      } 
      this.profileData['first_name'] = data[key][0].first_name
      this.profileData['middle_name'] = data[key][0].middle_name
      this.profileData['last_name'] = data[key][0].last_name
      this.profileData['dob'] = data[key][0].dob

      this.profileData['mobile'] = data[key][0]['~is_an'][0].mobile
      this.profileData['email'] = data[key][0]['~is_an'][0].email
      this.profileData['referrer_id'] = data[key][0]['~is_an'][0].referrer_id
      this.profileData['secondary_id'] = data[key][0]['~is_an'][0].secondary_id
      this.profileData['status'] = data[key][0]['~is_an'][0].status
    }
    else if(data.node_name == 'College'){
      this.school = false
      this.college = true
      this.professional = false
      this.certificate = false

      this.profileData['degree_type']=data.degree_type
      this.profileData['college_name']=data.college_name
      this.profileData['affiliated_to']=data.affiliated_to
      this.profileData['address']=data.address
      this.profileData['stream']=data.stream
      this.profileData['course']=data.course
      this.profileData['start_year']=data.start_year
      this.profileData['end_year']=data.end_year
      this.profileData['member_id']=data.member_id

      if(data.degree_type == 'Associate'){
        key= "~completed_associate_in"
      }else if(data.degree_type == 'Diploma'){
        key = "~completed_diploma_in"
      }else if(data.degree_type == 'Bachelor'){
        key = "~completed_bachelor_degree_in"
      }else if(data.degree_type == 'Master'){
        key = "~completed_master_degree_in"
      }else if(data.degree_type == 'Doctorate'){
        key = "~completed_doctorate_in"
      }  
      this.profileData['first_name'] = data[key][0].first_name
      this.profileData['middle_name'] = data[key][0].middle_name
      this.profileData['last_name'] = data[key][0].last_name
      this.profileData['dob'] = data[key][0].dob

      this.profileData['mobile'] = data[key][0]['~is_an'][0].mobile
      this.profileData['email'] = data[key][0]['~is_an'][0].email
      this.profileData['referrer_id'] = data[key][0]['~is_an'][0].referrer_id
      this.profileData['secondary_id'] = data[key][0]['~is_an'][0].secondary_id
      this.profileData['status'] = data[key][0]['~is_an'][0].status
    }
    else if(data.node_name == 'Certification'){
      this.school = false
      this.college = false
      this.professional = false
      this.certificate = true

      this.profileData['certification_course']=data.certification_course
      this.profileData['institution_name']=data.institution_name
      this.profileData['affiliated_to']=data.affiliated_to
      this.profileData['address']=data.address
      this.profileData['start_year']=data.start_year
      this.profileData['end_year']=data.end_year
      this.profileData['member_id']=data.member_id

      key = "~completed_certification_in"
      this.profileData['first_name'] = data[key][0].first_name
      this.profileData['middle_name'] = data[key][0].middle_name
      this.profileData['last_name'] = data[key][0].last_name
      this.profileData['dob'] = data[key][0].dob

      this.profileData['mobile'] = data[key][0]['~is_an'][0].mobile
      this.profileData['email'] = data[key][0]['~is_an'][0].email
      this.profileData['referrer_id'] = data[key][0]['~is_an'][0].referrer_id
      this.profileData['secondary_id'] = data[key][0]['~is_an'][0].secondary_id
      this.profileData['status'] = data[key][0]['~is_an'][0].status
    }
    else if(data.node_name == 'Profession'){
      this.school = false
      this.college = false
      this.professional = true
      this.certificate = false

      this.profileData['style']=data.style
      this.profileData['firm_name']=data.firm_name
      this.profileData['designation']=data.designation
      this.profileData['address']=data.address
      this.profileData['start_year']=data.start_year
      this.profileData['end_year']=data.end_year
      this.profileData['member_id']=data.member_id

      key = "~profession_info"
      this.profileData['first_name'] = data[key][0].first_name
      this.profileData['middle_name'] = data[key][0].middle_name
      this.profileData['last_name'] = data[key][0].last_name
      this.profileData['dob'] = data[key][0].dob

      this.profileData['mobile'] = data[key][0]['~is_an'][0].mobile
      this.profileData['email'] = data[key][0]['~is_an'][0].email
      this.profileData['referrer_id'] = data[key][0]['~is_an'][0].referrer_id
      this.profileData['secondary_id'] = data[key][0]['~is_an'][0].secondary_id
      this.profileData['status'] = data[key][0]['~is_an'][0].status
    }

    console.log("cc",this.profileData)
  }

  getAreaOfInterest(){
    const requestBody = {
          "domain_name": this.localService.getDomain(),
          "user_id": this.localService.getUserId(),
          "extras": {
              "find": {
                  "uid": this.profile_member_id
              }
          }
      }
      this.apiService.postApiCall(ENDPOINTS.GETUID, requestBody).subscribe(
        resp => {
          if (resp && resp.success == 1) {
            console.log("aoi",resp)
            if(resp.result.result.length){
              this.areaInterest = resp.result.result[0].interests
            }
          }
          else {
            this.notifyService.showError('Something Went Wrong', '');
            console.log("Invalid Error", resp);
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
          console.log("Error", err);
        }
      );
  }

  getAcademics(){
    const requestBody = {
          "domain_name": this.localService.getDomain(),
          "user_id": this.localService.getUserId(),
          "extras": {
              "find": {
                  "uid": this.profile_member_id
              }
          }
      }
      this.apiService.postApiCall(ENDPOINTS.GETSCHOOL, requestBody).subscribe(
        resp => {
          if (resp && resp.success == 1) {
            console.log("as",resp)
            this.profileData['school'] = resp.result.result
            // if(resp.result.result.length){
            //   this.areaInterest = resp.result.result[0].interests
            // }
          }
          else {
            this.notifyService.showError('Something Went Wrong', '');
            console.log("Invalid Error", resp);
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
          console.log("Error", err);
        }
      );
  }

  getAcademicsCollege(){
    const requestBody = {
          "domain_name": this.localService.getDomain(),
          "user_id": this.localService.getUserId(),
          "extras": {
              "find": {
                  "uid": this.profile_member_id
              }
          }
      }
      this.apiService.postApiCall(ENDPOINTS.GETCOLLEGE, requestBody).subscribe(
        resp => {
          if (resp && resp.success == 1) {
            console.log("as",resp)
            this.profileData['college'] = resp.result.result
            // if(resp.result.result.length){
            //   this.areaInterest = resp.result.result[0].interests
            // }
          }
          else {
            this.notifyService.showError('Something Went Wrong', '');
            console.log("Invalid Error", resp);
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
          console.log("Error", err);
        }
      );
  }

  getProfession(){
    const requestBody = {
          "domain_name": this.localService.getDomain(),
          "user_id": this.localService.getUserId(),
          "extras": {
              "find": {
                  "uid": this.profile_member_id
              }
          }
      }
      this.apiService.postApiCall(ENDPOINTS.GETPROFESSION, requestBody).subscribe(
        resp => {
          if (resp && resp.success == 1) {
            console.log("as",resp)
            this.profileData['profession'] = resp.result.result
            // if(resp.result.result.length){
            //   this.areaInterest = resp.result.result[0].interests
            // }
          }
          else {
            this.notifyService.showError('Something Went Wrong', '');
            console.log("Invalid Error", resp);
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
          console.log("Error", err);
        }
      );
  }

  getCertificate(){
    const requestBody = {
          "domain_name": this.localService.getDomain(),
          "user_id": this.localService.getUserId(),
          "extras": {
              "find": {
                  "uid": this.profile_member_id
              }
          }
      }
      this.apiService.postApiCall(ENDPOINTS.GETCERTIFICATE, requestBody).subscribe(
        resp => {
          if (resp && resp.success == 1) {
            console.log("as",resp)
            this.profileData['certification'] = resp.result.result
            // if(resp.result.result.length){
            //   this.areaInterest = resp.result.result[0].interests
            // }
          }
          else {
            this.notifyService.showError('Something Went Wrong', '');
            console.log("Invalid Error", resp);
          }
        },
        err => {
          this.notifyService.showError('Something Went Wrong', '');
          console.log("Error", err);
        }
      );
  }

  chat(){
    this.chatService.selectUser(this.profile_mobile)
    console.log(this.profile_member_id, this.d)
  }

  mobileChat(){
    this.routeUrl.navigate(['/chat'+"/"+this.profile_member_id])
  }
}
