import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { DOMAIN_NAME, USER_ID } from 'src/app/app.config';
import { ENDPOINTS } from 'src/app/app.config';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  member_number: any;
  institute_number: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.instituteNumber()
    this.memberNumber()
  }
  onlogin() {
    this.router.navigate(['/login'], { relativeTo: this.route, skipLocationChange: false })
  }
  onregistration() {
    this.router.navigate(['/registration'], { relativeTo: this.route, skipLocationChange: false })
  }
  onhome() {
    this.router.navigate(['/'], { relativeTo: this.route, skipLocationChange: false })
  }
  memberNumber() {
    const reqBody = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.apiService.postApiCall(ENDPOINTS.MEMBERS_COUNT, reqBody).subscribe(
      resp => {
        this.member_number = resp.count
      })
  }
  instituteNumber(){
    const reqBody = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "extras": {
        "find": {}
      }
    }
    this.apiService.postApiCall(ENDPOINTS.INSTITUTE_COUNT, reqBody).subscribe(
      resp => {
        this.institute_number = resp.count
      })
  }




}
