<div>
    <h2 class="form-heading font-shadow">&nbsp;&nbsp;Edit Personal Details</h2>
    <div>
        <div class="text-center" style="margin-bottom: 30px; ">
            <!-- <button class="btn btn-outline-primary text-center" style="margin-right:1rem" (click)="back()"><i class="fa fa-home" style="margin-right:0.5rem"></i>Home</button> -->
        </div>

        <div class="container">
            <form [formGroup]="form" class="form" novalidate style="background-color:#FFF7E9">
                <div class="card-body"  >
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">Change Profile Picture <span class="required">*</span></label>
                        </div>
                        <div class="col-md-5">
                            <input style="width: 245px;" class="form-control" (change)="imageUpload($event,'picture')"
                                type="file" [class.is-invalid]="validateForm && f.picture.errors" />

                            <div class="invalid-feedback" *ngIf="f.picture.errors">
                                <!-- <div *ngIf="f.picture.type">Allowed files are PNG,JPG,JPEG.</div> -->
                                <!-- <div *ngIf="f.picture.high">Maximum file size is 2MB only.</div> -->
                            </div>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                First Name<span class=' text-danger'>*</span>
                            </label>
                        </div><br>

                        <div class="col-md-5">
                            <input style="width: 245px;" type="text" class="form-control" formControlName="first_name"
                                [class.is-invalid]="f.first_name.invalid && validateForm" required>
                            <div class="invalid-feedback" *ngIf="f.first_name.errors">
                                <div *ngIf="f.first_name.errors.required">
                                    first name is required.
                                </div>
                                <div *ngIf="f.first_name.errors.pattern">
                                    Invalid first Name.
                                </div>
                                <div *ngIf="f.first_name.errors.maxlength">
                                    Max Length
                                </div>
                                <div *ngIf="f.first_name.errors.minength">
                                    Min Length
                                </div>
                            </div>
                        </div>
                        <div class="col-md"></div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                Middle Name
                            </label>
                        </div>
                        <div class="col-md-5">
                            <input style="width: 245px;" type="text" class="form-control" formControlName="middle_name"
                                [class.is-invalid]="f.middle_name.invalid && validateForm">
                            <div class="invalid-feedback" *ngIf="f.middle_name.errors">
                                <div *ngIf="f.middle_name.errors.pattern">
                                    Invalid middle_name.
                                </div>
                                <div *ngIf="f.middle_name.errors.maxLength">
                                    Max Length is 25 characters
                                </div>
                            </div>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                Last Name<span class=' text-danger'>*</span>
                            </label>
                        </div>
                        <div class="col-md-5">
                            <input style="width: 245px;" type="text" class="form-control" formControlName="last_name"
                                [class.is-invalid]="f.last_name.invalid && validateForm" required>
                            <div class="invalid-feedback" *ngIf="f.last_name.errors">
                                <div *ngIf="f.last_name.errors.required">
                                    last_name is required.
                                </div>
                                <div *ngIf="f.last_name.errors.pattern">
                                    Invalid last_name.
                                </div>
                                <div *ngIf="f.last_name.errors.maxlength">
                                    Max Length is 25 characters
                                </div>
                            </div>
                        </div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                Date of Birth<span class=' text-danger'>*</span>
                            </label>
                        </div>
                        <div class="col-md-5">
                            <input type="date" style="width: 245px;" [max]="max_date" class="form-control"
                                formControlName="dob" [class.is-invalid]="f.dob.invalid && validateForm" required>
                            <div class="invalid-feedback" *ngIf="f.dob.errors">
                                <div *ngIf="f.dob.errors.required">
                                    Date of Birth is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md"></div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                Change Current City<span class='text-danger'>*</span>
                            </label>
                        </div>
                        <div class="col-md-5">
                            <input ngx-google-places-autocomplete type="text" class="form-control"
                            [options]='options' formControlName="city"
                            placeholder="Enter Current City" #placesRef="ngx-places"
                            (onAddressChange)="changeAddress($event)" style="width: 245px;" required>
                            <div class="invalid-feedback" *ngIf="f.dob.errors">
                                <div *ngIf="f.dob.errors.required">
                                    Date of Birth is required.
                                </div>
                            </div>
                        </div>
                        <div class="col-md"></div>
                    </div><br>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                Email<span class=' text-danger'>*</span>
                            </label>
                        </div>
                        <div class="col-md-5">
                            <input style="width: 245px;" type="text" class="form-control" formControlName="email"
                                [class.is-invalid]="f.email.invalid && validateForm" required>
                            <div class="invalid-feedback" *ngIf="f.email.errors">
                                <div *ngIf="f.email.errors.required">
                                    Email is required.
                                </div>
                                <div *ngIf="f.email.errors.pattern">
                                    Invalid Email.
                                </div>
                            </div>
                        </div>
                    </div><br>
                    <!--gooogle map-->
                    <!-- <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                location<span class=' text-danger'>*</span>
                            </label>
                        </div>
                        <div class="col-md-5">
                            <input ngx-google-places-autocomplete type="text" class="form-control" [options]='options'
                                id="location" formControlName="location" placeholder="Enter location"
                                #placesRef="ngx-places" (onAddressChange)="changeAddress($event)">
                        </div>
                    </div><br> -->
                    <!-- <div class="col-md-5">    
                        <label>
                            <input ngx-google-places-autocomplete [options]='options' id="enterlocation" type="text" class="form-control" formControlName="location" placeholder="Enter Location" #placesRef="ngx-places">
                            <span class="checkout-label">Enter Location*</span>
                        </label>
                    </div> -->
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                Mobile Number<span class=' text-danger'>*</span>
                            </label>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group" style="width: 245px;">
                                <input type="text" class="form-control" formControlName="mobile"  aria-describedby="button-addon2"
                                [class.is-invalid]="f.mobile.invalid && validateForm" (keyup)="check()" required>
                                <!-- <input type="text" class="form-control" placeholder="Recipient's username" aria-label="Recipient's username" aria-describedby="button-addon2"><!-->
                                <button *ngIf="is_active" class="btn btn-info" type="button" id="button-addon2" (click)="sendMobileOTP()">Validate</button>
                                <button data-bs-toggle="modal" id="modalotp" data-bs-target="#staticBackdrop"
                                style="display: none;"></button>
                              </div>
                            <!-- <input style="width: 245px;" type="text" class="form-control" formControlName="mobile"  aria-describedby="button-addon2"
                                [class.is-invalid]="f.mobile.invalid && validateForm" (keyup)="check()" required>
                                <button type="button" class="btn btn-info" data-bs-toggle="modal" id="button-addon2">Validate</button>
                                    <button type="button" class="btn btn-primary btn-block my-4" *ngIf="is_active"
                                (click)="sendMobileOTP()" style="float: right; "></button>
                            <button data-bs-toggle="modal" id="modalotp" data-bs-target="#staticBackdrop"
                                style="display: none;"></button> -->
                            
                        </div>
                        <!--modal-->
                        <div>
                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static"
                                data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
                                aria-hidden="true">
                                <div class="modal-dialog modal-sm">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h5 class="modal-title" id="staticBackdropLabel">
                                                Mobile OTP Verification
                                            </h5>
                                            <button type="button" id="close" class="btn-close" data-bs-dismiss="modal"
                                                aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <form class="form" [formGroup]="otpForm" novalidate>
                                                <div class="form-group">
                                                    <label for="otp" class="form-label">Enter OTP sent to your mobile
                                                        number</label>
                                                    <input type="text" class="form-control" formControlName="otp"
                                                        [class.is-invalid]="g.otp.invalid" />
                                                    <div class="invalid-feedback" *ngIf="g.otp.errors">
                                                        <div *ngIf="g.otp.errors.incorrect">Invalid OTP.</div>
                                                    </div>
                                                </div>
                                                <br />
                                                <button class="btn btn-outline-primary btn-sm"  data-bs-toggle="modal" id="modalotp" (click)="sendMobileOTP()"
                                                    data-bs-target="#staticBackdrop">
                                                    Resend
                                                </button>
                                                <button class="btn btn-outline-success btn-sm" style="float: right"
                                                    (click)="validateOtp()">
                                                    Submit
                                                </button>
                                            </form>
                                        </div>
                                        <!-- <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                            <button type="button" class="btn btn-primary">Understood</button>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="invalid-feedback" *ngIf="f.mobile.errors">
                            <div *ngIf="f.mobile.errors.required">
                                Mobile is required.
                            </div>
                            <div *ngIf="f.mobile.errors.max">
                                Invalid Mobile.
                            </div>
                        </div>
                    </div>
                    <br>
                    <hr>
                    <h3 class="form-heading font-shadow">&nbsp;&nbsp;Family Information</h3>
                    <br>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                Marital Status
                            </label>
                        </div>
                        <div class="col-md-5">
                            <select class="form-control form-select" id="status" formControlName="marital_status"
                                style="width: 245px;">
                                <option value="Status">Select Marital Status</option>
                                <option value="Married" selected>
                                    Married
                                </option>
                                <option value="Single">
                                    Single
                                </option>
                                <option value="Widow">
                                    Widow
                                </option>
                            </select>

                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-5">
                            <label class="control-label">
                                Have Kids
                            </label>
                        </div>
                        <div class="col-md-5">
                            <div>
                                <input name="have_kids" formControlName="have_kids" class="form-check-input"
                                    type="radio" name="have_kids" id="inlineRadioYes" value="Yes">
                                <label class="form-check-label" for="inlineRadioYes">Yes</label>
                                &nbsp;
                                <input name="have_kids" formControlName="have_kids" class="form-check-input"
                                    type="radio" id="inlineRadioNo" value="No">
                                <label class="form-check-label" for="inlineRadioNo">No</label>
                            </div>
                        </div>
                    </div>


                </div>

                <div>
                    <button type="button" class="btn btn-primary btn-block my-4" (click)="cancel()">Cancel</button>
                    <button type="button" class="btn btn-primary btn-block my-4 float-left" *ngIf = "!is_active" style="float: right;"
                        (click)="submit()">Update
                    </button>
                </div>
            </form>
        </div>
    </div>
    <br>
</div>
<br><br>