import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ENDPOINTS } from 'src/app/app.config';
import { ApiService } from 'src/app/services/api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { DatePipe } from '@angular/common';
import { DOMAIN_NAME, USER_ID } from 'src/app/app.config';
import { Location } from '@angular/common';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
// import { Options } from 'ngx-google-places-autocomplete/objects/options/options';



@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [DatePipe]
})
export class EditComponent implements OnInit {
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  options: any = {
    componentRestrictions: { country: ['IN'] }
    }
    latitude: number = 22.2736308;
    longitude:number = 70.7512555;
  form!: FormGroup;
  validateForm: boolean = false;
  member_id = "";
  screenData: any = {};
  fieldList: any = {};
  route: any;
  groupsWithFieldsFiltered: any = [];
  formFields: any = [];
  columnNumClass = "col-md-6";
  screenName = "";
  url: any;
  field: any
  pincode: any
  dependentfields: []
  uidpersonal: any
  uidpincode: any
  uidvillage: any
  uidstate: any
  uidcountry: any
  max_date: any
  uid: any;
  otpForm: FormGroup;
  mobile_no: any;
  is_active: boolean = false;
  length: any;
  familyuid: any;
  zoom: number;
  uidcity: any;



  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private localStorage: LocalStorageService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private notifyService: NotificationService,
    private _location: Location,
    private datePipe: DatePipe
  ) {


  }

  ngOnInit(): void {
    let maxdate = 3650
    if (maxdate) {
      var date = new Date();
      var maximum = date.setDate(date.getDate() - maxdate);
      this.max_date = this.datePipe.transform(maximum, 'yyyy-MM-dd')
      console.log("date", this.max_date
      )
    }
    this.route = this.router.url.split('/')[2]
    console.log("mmm", this.route)
    this.otpForm = new FormGroup({
      otp: this.formBuilder.control('', [Validators.required])
    });
    this.form = new FormGroup({
      first_name: this.formBuilder.control('', [Validators.required, Validators.maxLength(25), Validators.minLength(3), Validators.pattern('^[a-zA-Z ]*$')]),
      middle_name: this.formBuilder.control('', [Validators.maxLength(80), Validators.pattern('^[a-zA-Z ]*$')]),
      last_name: this.formBuilder.control('', [Validators.required, Validators.maxLength(25), Validators.pattern('^[a-zA-Z ]*$')]),
      email: this.formBuilder.control('', [Validators.required, Validators.pattern('^(\s?[a-z0-9+_.-]+@[a-z0-9]+[.][a-z]+\s?,)*(\s?[a-z0-9+_.-]+@[a-z0-9]+[.][a-z+]+)$')]),
      mobile: this.formBuilder.control('', [Validators.required, Validators.maxLength(10), Validators.pattern("^[+]*[(]{0,1}[0-9]{10}[)]{0,1}[-\s\./0-9]*$"), Validators.maxLength(10)]),
      // patternnned to addEventListener
      picture: this.formBuilder.control(''),
      dob: this.formBuilder.control('', [Validators.required]),
      city: this.formBuilder.control('',),
      state: this.formBuilder.control('',),
      country: this.formBuilder.control('',),
      marital_status: this.formBuilder.control('',),
      have_kids: this.formBuilder.control('',),
      location: this.formBuilder.control('',)
    });
    this.localStorage.set("domainName", DOMAIN_NAME)
    this.memberData()
    this.familyget()
    
  }
  get f() {
    return this.form.controls;
  }
  get g() {
    return this.otpForm.controls;
  }
  memberData() {

    console.log("member", this.route)
    const requestBody = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "extras": {
        "find": {
          "uid": this.route
        }
      }
    }
    console.log(requestBody);
    this.apiService.postApiCall(ENDPOINTS.GET_MEMBER, requestBody).subscribe(
      resp => {
        console.log("Member details", resp.result);
        const data1 = resp.result
        this.uidpersonal = data1.result[0].is_an[0].uid
        this.uidcity = data1.result[0].in[0].uid
        this.uidstate = data1.result[0].in[0].in[0].uid
        this.uidcountry = data1.result[0].in[0].in[0].in[0].uid
        this.uid = data1.result[0].uid
        this.mobile_no = data1.result[0].mobile
        console.log("mobile_no", this.mobile_no)
        console.log("this", this.uidcountry)
        // console.log("secon", this.secondary_id)
        // console.log("Member details2", JSON.parse(resp.result));
        this.dataDeNormalize(resp.result);
      },
      err => {
        console.log("Error: ", err);
      }
    );
  }

  dataDeNormalize(data: any) {
    for (const key in data.result[0]) {
      if (Object.prototype.hasOwnProperty.call(data.result[0], key)) {
        const element = data.result[0][key];
        if (typeof element != 'object') {
          this.fieldList[key] = element;
          console.log("trmas", key, "element", element)
        }
        else {
          this.readObjectData(element);
        }
      }
    }
    this.fieldList["uid"] = this.member_id;
    console.log("ALL FIELDS", this.fieldList);
    this.form.patchValue(this.fieldList);
    console.log("value", this.form.value)
  }

  readObjectData(object: { [x: string]: any; }) {
    let temp = [];
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];

        if (typeof element != 'object') {
          // console.log("Test", key, element);
          if (key == "is_an") {
            console.log("Entity UID: ", object[key][0])
          }
          this.fieldList[key] = element;
          if (!this.member_id && key == 'uid') {
            this.member_id = element;
          }
        }
        else {
          this.readObjectData(element);
        }
      }
    }
  }

  getFieldValue(key: string | number) {
    return this.fieldList[key];
  }
  imageUpload(event: any, name: string) {
    let file_data = { name: "", size: 0 }
    //CHECKING DRAG EVENT OR UPLOAD EVENT
    if (event.target && event.target.files && event.target.files[0]) {

      file_data = event.target.files[0]
    }

    var reader = new FileReader();

    let splitFileName = file_data.name.split(".")

    //CHECKING FILE NAME
    if (splitFileName.length == 0) {
      this.form.controls[name].setErrors({ invalid: true })
      return
    }

    if (file_data.size == 0) {
      this.form.controls[name].setErrors({ zero: true })
      return
    }

    if (Math.round((file_data.size / 1000)) > 2000) {
      this.form.controls[name].setErrors({ high: true })
      return
    }
    //CHECKING FILE TYPE
    if (!["jpg", "png", "gif", "JPG", "PNG", "GIF"].includes(splitFileName[splitFileName.length - 1])) {
      this.form.controls[name].setErrors({ type: true })
      return
    }
    this.form.controls[name].setErrors({ invalid: false })
    this.form.controls[name].setErrors({ type: false })
    this.form.controls[name].setErrors({ high: false })
    this.form.controls[name].setErrors({ zero: false })
    reader.onload = (evt: any) => {
      console.log("coming")
      // this.url =event.target.result
      this.apiService.uploadImage(event.target.files[0], 'picture').then((resp) => {
        console.log("coming",resp)
        this.form.controls[name].setValue(resp);
      })
    }
    //BASED ON EVENT READ FILE 
    reader.readAsDataURL(event.target.files[0]);
  }

  fieldControl(fieldName: any) {
    return this.form.value[fieldName];
  }
  changeAddress(address: any) {
    console.log("address",address)
    // this.form.controls["College Details"].setValue(address.formatted_address);
    // this.latitude = address.geometry.location.lat()
    // this.longitude = address.geometry.location.lng()
    // console.log("lat long", this.latitude)
    // this.getLocationdetails()
  }

  // search(pincode: any) {
  //   // console.log("pincode",this.form.controls["pincode"].value)
  //   // console.log(field);
  //   // let index = this.groupsWithFieldsFiltered.indexOf(pincode);
  //   // pincode.dependentfields.forEach((element: any) => {
  //   //   this.form.controls[element].setValue("");
  //   // });

  //   // this.groupsWithFieldsFiltered[index].attributes.options = [];
  //   if (this.form.value["pincode"].length == 6) {
  //     let requestBody = {
  //       // "domain_name": "static",
  //       "domain_name": DOMAIN_NAME,
  //       "user_id": USER_ID,
  //       "payload": {
  //       },
  //       "extras": {
  //         "find": {
  //           "pincode": this.form.value["pincode"],
  //         },
  //         "pagination": false,
  //         "paginationDetails": {
  //           "limit": 10,
  //           "pageNumber": 2
  //         }
  //       }
  //     }
  //     // console.log(ENDPOINTS.GET_LOCALITY, requestBody);
  //     this.apiService.postApiCall(ENDPOINTS.GET_LOCALITY, requestBody).subscribe(
  //       resp => {
  //         if (resp.result.data.length > 0) {
  //           // this.form.controls[pincode.fieldname].setErrors(null);
  //           console.log("Success", resp);
  //           this.field = resp.result.data;

  //           // console.log(this.groupsWithFieldsFiltered.indexOf(pincode));
  //           // console.log(this.groupsWithFieldsFiltered[this.groupsWithFieldsFiltered.indexOf(field)].attributes.options);

  //         }
  //       }
  //     );
  //   }
  //   // else {
  //   //   this.form.controls[pincode.fieldname].setErrors({ 'incorrect': true });
  //   // }
  // }
  // localitySelected(location: any) {
  //   this.form.controls['pincode'].setErrors(null);
  //   console.log("Selected", location);
  //   this.form.controls['state'].setValue(location.state);
  //   this.form.controls['country'].setValue(location.country);
  //   console.log("value", this.form.value)

  // }
  selectLocation(event: any) {
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.zoom = 15;
    // this.getLocationdetails();
  }
  cancel() {
    this._location.back();
  }
  submit() {
    this.validation()
    this.familyInformation()
    let requestbody1 = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "payload": {
        "update": {
          "uid": this.uidpersonal,
          "last_name": this.form.value["last_name"],
          "middle_name": this.form.value["middle_name"],
          "first_name": this.form.value["first_name"],
          "picture": this.form.value["picture"],
          "dob": this.form.value["dob"]
        }
      }
    }
    let requestbody2 = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "payload": {
        "update": {
          "uid": this.route,
          "mobile": this.form.value["mobile"],
          "email": this.form.value["email"],
        }
      }
    }
    let requestbody4 = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "payload": {
        "update": {
          "uid": this.uidcountry,
          "country": this.form.value["country"]

        }
      }
    }
    let requestbody5 = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "payload": {
        "update": {
          "uid": this.uidstate,
          "state": this.form.value["state"]

        }
      }
    }
    let requestbody6 = {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "payload": {
        "update": {
          "uid": this.uidvillage,
          "city": this.form.value["city"]
        }
      }
    }
    console.log(this.form)
    // if(this.otpForm.valid){
      if (this.form.valid){
      this.validateForm = false
      this.update(requestbody1)
      this.update(requestbody2)
      this.update(requestbody4)
      this.update(requestbody5)
      this.update(requestbody6)
      this.updateChatProfile()
      console.log("nnnnn", this.form)
      const req = {
        "domain_name": DOMAIN_NAME,
        "user_id": USER_ID,
        "payload": {
          "customer_creation": {
            "phone_number": this.form.value["mobile"],
            "email": this.form.value["email"],
          }
        },
        "extras": {
          "find": {
            "member_id": this.uid
          }
        }
      }
      this.apiService.postApiCall(ENDPOINTS.UPDATE_DETAILS, req).subscribe(
        resp => {
          console.log("resp", resp, this.uid)
          this.notifyService.showSuccess('Updated Successfully', 'Member Details')
        })
      }
      else{
        this.validateForm = true
        this.notifyService.showError('form is invalid', 'Member Details');
      }
    // }
    // else {
    //   this.notifyService.showError('Please validate your Mobile No. through OTP', 'Member Details');
    // }

  }

 

  update(requestbody: any) {
    this.apiService.postApiCall(ENDPOINTS.UPDATE_REGISTRATION_STATUS, requestbody).subscribe(
      resp => {
        console.log(resp)
        if (requestbody.payload.update.picture != undefined) {
          this.localStorage.set("picture", requestbody.payload.update.picture)
          this.apiService.PROFILE_PICTURE = requestbody.payload.update.picture
        }
      })

    let URL = "/member/myprofile/" + this.route
    this.router.navigate([URL]);
  }
  check() {
    if (this.mobile_no != this.form.value.mobile) {
      this.is_active = true
    }
    else{
      this.is_active = false
    }
  }

  updateChatProfile(){
    const requestBody = {"payload": {
		"update": {
			"member_id": this.route,
			"username": this.form.value.mobile,
			"firstname": this.form.value.first_name
		}
	}
  }
  this.apiService.postApiCall(ENDPOINTS.UPDATEPROFILE, requestBody).subscribe(
    resp => {
    },
    err => {
      console.log("Error: ", err);
    }
  );
  }
  sendMobileOTP() {
    if (this.is_active = true) {
      this.validateForm = false;
      const requestBody = {
        "data": {
          "otp": {
            "mobile": this.form.value.mobile,
            "otppurpose": "register"
          }
        }
      }
      this.apiService.postApiCall(ENDPOINTS.SEND_OTP_MOBILE, requestBody).subscribe(
        resp => {
          if (resp && resp.success == 1) {
            document.getElementById("modalotp").click()
            this.notifyService.showInfo('OTP Sent to your mobile number', 'Mobile Verification');
            console.log("OTP SENT", resp);
          }
          else {
            this.notifyService.showError('Something Went Wrong!', 'Mobile Verification');
            console.log("Invalid Mobile", resp);
          }
        })
    }
  }
  validateOtp() {
    // document.getElementById('close').setAttribute('data-dismiss','modal');
    const requestBody = {
      "data": {
        "otp": {
          "userotp": this.otpForm.value.otp,
          "mobile": this.form.controls["mobile"].value
        }
      }
    }
    this.apiService.postApiCall(ENDPOINTS.VERIFY_OTP_MOBILE, requestBody).subscribe(
      resp => {
        if (resp && resp.success == 1) {
          this.is_active = false
          console.log("OTP VERIFIED", resp);
          document.getElementById("close")?.click();
          // document.getElementById("close").click();
        }
        else {
          this.otpForm.controls["otp"].setErrors({ 'incorrect': true });
          console.log("WRONG OTP", requestBody, resp);
          this.notifyService.showWarning('Invalid OTP!', 'Mobile Verification');
        }
      },
      err => {
        this.notifyService.showError('Something Went Wrong!', 'Mobile Verification');
        console.log("Error", err);
        // this.submit();
        // document.getElementById("close")?.click();
      })

  }
  familyget() {
    const reqBody =
    {
      "domain_name": DOMAIN_NAME,
      "user_id": USER_ID,
      "extras": {
        "find": {
          "uid": this.route
        }
      }
    }
    this.apiService.postApiCall(ENDPOINTS.FAMILY_GET, reqBody).subscribe(
      resp => {
        console.log("ram", resp.result.result.length)
        this.length = resp.result.result.length
        this.familyuid = resp.result.result[0].uid
        this.form.controls.marital_status.setValue(resp.result.result[0].marital_status)
        this.form.controls.have_kids.setValue(resp.result.result[0].have_kids)

      })


  }
  familyInformation() {
    if (this.length == 0) {
      const reqBody = {
        "domain_name": DOMAIN_NAME,
        "user_id": 17,
        "payload": {
          "family": {
            "member": {
              "uid": this.route
            },
            "family": {
              "marital_status": this.form.value.marital_status,
              "have_kids": this.form.value.have_kids
            }
          }
        }
      }
      this.apiService.postApiCall(ENDPOINTS.FAMILYSEND, reqBody).subscribe(
        resp => {
          if (resp && resp.success == 1) {
            this.notifyService.showSuccess('Family Details Saved', 'FamilyInformation');
          }
          else {
            this.notifyService.showError('Something Went Wrong!', 'FamilyInformation');
          }
        })

    }
    else if (this.length > 0) {
      const reqBody = {
        "domain_name": DOMAIN_NAME,
        "user_id": USER_ID,
        "payload": {
          "update": {
            "uid": this.familyuid,
            "marital_status": this.form.value.marital_status,
            "have_kids": this.form.value.have_kids
          }
        }

      }
      this.apiService.postApiCall(ENDPOINTS.UPDATE_REGISTRATION_STATUS, reqBody).subscribe(
        resp => {
          if (resp && resp.success == 1) {
            return
            
          }
          else {
            this.notifyService.showError('Something Went Wrong!', 'FamilyInformation');
          }
        })
    }
    else{
      this.notifyService.showError('Something Went Wrong!', 'FamilyInformation');
    }
  }
  validation(){
    var date = new Date().getTime()
    var dob = new Date(this.form.controls["dob"].value).getTime()
    var validaiton = (date - dob) / (1000 * 3600 * 365 * 24)
    console.log("mmm",date,dob,validaiton)
    if (validaiton <10 ) {
      this.form.controls["dob"].setErrors({ 'incorrect': true });
      this.notifyService.showInfo('For using this application age should be 10 years', 'PresentSir');
    } 
    if(this.form.controls["state"].value == "" || this.form.controls["country"].value == "") {
      this.notifyService.showInfo('Please select the address from Autosuggestion', 'PresentSir');   
    }
  }
}



